export default class ProfileUpdateModel {
  constructor(
    public firstName: string | null | undefined,
    public lastName: string | null | undefined,
    public postalCode: string | null | undefined,
    public gender: string | null | undefined,
    public email: string | null | undefined,
    public birthDate: Date | null | undefined,
  ) {}
}

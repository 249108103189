// Checks if the user is using an Apple based device.
const isIos = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test(userAgent);
};

// @ts-ignore
const { standalone } = window.navigator;

// Checks if the user is launching the application from the browser, or in PWA
// mode.
const isInStandaloneMode = () => 'standalone' in window.navigator && standalone;

export { isIos, isInStandaloneMode };

import React, { Fragment, ReactNode } from 'react';
import DetailPageStyles from './DetailPageStyles';
import {
  Button,
  Container,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import { EXTERNAL_LINK } from '../../../common/constants/promotion-constants';
import { history } from '../../../index';
import HeroImageModel from '../../../common/models/app/HeroImageModel';
import LinkModel from '../../../common/models/app/LinkModel';
import { ga } from 'react-ga';
import TopAppbar from '../top-appbar';

interface DetailPageProps {
  title?: string;
  backButtonAction?: Function;
  HeroImage?: HeroImageModel;
  CaptionText?: string;
  BodyContent?: string | ReactNode;
  Links?: LinkModel[];
  externalLinkTagType?: string;
  LinkButtonStyleVariant?: boolean;
}

const DetailPage: React.FC<DetailPageProps> = (props) => {
  const classes = DetailPageStyles();
  return (
    <Fragment>
      <TopAppbar
        backButtonActionOrUrl={props.backButtonAction}
        isDetailAppBar={true}
      />
      <div className={classes.root}>
        <img
          src={props.HeroImage?.src}
          alt={props.HeroImage?.alt}
          className={classes.image}
        />
        <Container maxWidth={'lg'}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                variant={'h6'}
                className={classes.textMargins}
                noWrap={false}
                color={'primary'}
              >
                {props.CaptionText}
              </Typography>
              <Divider color={'primary'} />
            </Grid>
            {React.isValidElement(props.BodyContent) ? (
              <Grid item xs={12}>
                {props.BodyContent}
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Typography
                  component={'div'}
                  variant={'body1'}
                  gutterBottom={true}
                  className={classes.textMargins}
                >
                  <p
                    //@ts-ignore
                    dangerouslySetInnerHTML={{ __html: props.BodyContent }}
                  />
                </Typography>
              </Grid>
            )}
            {props.Links?.map((link) => {
              return (
                <Grid key={link.name} container item xs={12} justify={'center'}>
                  <Button
                    size={'large'}
                    variant={'contained'}
                    className={
                      props.LinkButtonStyleVariant
                        ? classes.linkButtonWhiteBackground
                        : classes.linkButtonGreenBackground
                    }
                    onClick={() => {
                      if (link.type === EXTERNAL_LINK) {
                        ga(
                          'send',
                          'event',
                          'outbound',
                          'click',
                          `${props.externalLinkTagType || 'Promotion'} - ${
                            link.url
                          }`,
                          {
                            transport: 'beacon',
                            hitCallback: function () {
                              console.log(link.url);
                            },
                          },
                        );
                        window.open(link.url, '_blank');
                      } else {
                        history.push(link.url);
                      }
                    }}
                  >
                    {link.name}
                  </Button>
                </Grid>
              );
            })}
          </Grid>
        </Container>
      </div>
    </Fragment>
  );
};

export default DetailPage;

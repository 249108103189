import React, { Dispatch, SetStateAction } from 'react';
import { Button } from '@material-ui/core';
import { ga } from 'react-ga';
import DealStyles from './DealStyles';

interface RedeemDealButtonProps {
  title?: string;
  showConfirmDialog: Dispatch<SetStateAction<boolean>>;
}

const RedeemDealButton: React.FC<RedeemDealButtonProps> = (props) => {
  const classes = DealStyles();
  return (
    <Button
      size={'large'}
      variant={'contained'}
      color={'secondary'}
      className={classes.redeemButton}
      onClick={() => {
        ga('send', 'event', 'redeemed deals', 'click', `${props?.title}`, {
          transport: 'beacon',
          hitCallback: function () {
            console.log(props?.title);
          },
        });
        props.showConfirmDialog(true);
      }}
    >
      REDEEM
    </Button>
  );
};

export default RedeemDealButton;

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const ProfileStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: '90px',
      marginBottom: '80px',
    },
    avatar: {
      width: 100,
      height: 100,
      backgroundColor: 'inherit',
    },
    userImage: {
      width: 'inherit',
      height: 'inherit',
      color: theme.palette.primary.dark,
    },
    infoPaper: {
      width: 150,
      height: 150,
    },
    saveProfileButton: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.contrastText,
    },
    editIconButton: {
      color: theme.palette.background.paper,
    },
    inputFile: {
      display: 'none',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }),
);

export default ProfileStyles;

import React, { ReactNode, useContext, useEffect } from 'react';
import { Slide } from '@material-ui/core';
import Stores from '../../../domain/stores/Stores';
import { observer } from 'mobx-react-lite';
import WrapperStyles from './WrapperStyles';
import Notification from '../notifications';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import BottomAppBar from '../bottom-appbar/BottomAppBar';
import Loading from '../loading/Loading';

interface WrapperProps extends RouteComponentProps {
  children: ReactNode;
}

const Wrapper: React.FC<WrapperProps> = ({ children, location }) => {
  const { userStore, globalStore } = useContext(Stores);
  const classes = WrapperStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <Slide timeout={250} key={location.key} in={true} direction={'left'}>
      <div className={classes.root} id={'wrapperRoot'}>
        <Loading />
        {children}
        {userStore.userModel?.Data && <BottomAppBar />}
        <Notification
          Type={globalStore.notification.Type}
          Title={globalStore.notification.Title}
          Message={globalStore.notification.Message}
          Open={globalStore.notification.Open}
          OnClose={globalStore.notification.OnClose}
        />
      </div>
    </Slide>
  );
};

export default withRouter(observer(Wrapper));

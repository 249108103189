import { action, observable } from 'mobx';
import WemRoutes from '../../../common/config/routes/wem-routes';
import DealCategoryModel from '../../../common/models/loyalty/deals/DealCategoryModel';
import DealModel from '../../../common/models/loyalty/deals/DealModel';
import { get, post, remove } from '../../../common/network/agent';
import BaseStore from '../BaseStore';
import { format } from 'date-fns';
import _ from 'lodash';

/**
 * An observable class that holds data about deals.
 * */
export default class DealsStore extends BaseStore {
  @observable categories: DealCategoryModel[] = []; // The deal categories
  @observable filteredCategories: DealCategoryModel[] = []; // stores the deal category filters
  @observable deals: DealModel[] = []; // contains the user deals
  @observable filterByFavorites: boolean = false;

  /**
   * Used to set the category filters the user has selected.
   * @param filters the category filter ids selected by the user
   */
  @action
  public applyFilters = (filters: DealCategoryModel[]) => {
    this.filterByFavorites = false;
    _.remove(this.filteredCategories);
    if (filters.length > 0) {
      this.filteredCategories = filters;
    }
  };

  /**
   * Remove a filtered category
   * @param categoryId the id of the category to remove
   */
  @action
  public removeCategoryFilter = (categoryId: number) => {
    this.filteredCategories = this.filteredCategories.filter(
      (category) => category.id !== categoryId,
    );
  };

  /**
   * Gets the available deal categories.
   * */
  @action
  public getDealCategories = async () => {
    try {
      this.isLoading = true;
      const response = await get(`${WemRoutes.dealCategories}`);
      if (response.ok) {
        this.categories = (await response.json()) as DealCategoryModel[];
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.isLoading = false;
    }
  };

  /**
   * Gets all the deals for the user, which include the redeemed, and saved state (favorites).
   * @param userId the id of the current logged in user.
   * */
  @action
  public getDeals = async (userId: number) => {
    try {
      this.isLoading = true;

      // Get the user id and pass it as part of the api route
      const response = await get(`${WemRoutes.deals}/${userId}/deals`);

      if (response.ok) {
        this.deals = (await response.json()) as DealModel[];
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.isLoading = false;
    }
  };

  /**
   * Used to trigger a deal redemption for the user.
   * @param userId the id of the current logged in user.
   * @param dealId the id of the deal to redeem.
   */
  @action
  public redeemDeal = async (userId: number, dealId: number) => {
    try {
      this.isLoading = true;

      const response = await post(
        `${WemRoutes.deals}/${userId}/deals/redeem/${dealId}`,
        {},
      );

      if (response.ok) {
        let deal = this.deals.find((f) => f.id === dealId);
        if (deal) {
          deal.redeemed = true;
          this.replaceADeal(deal);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.isLoading = false;
    }
  };

  /**
   * Adds a deal to the user's favorites.
   * @param userId the id of the current logged in user.
   * @param dealId the id of the deal to add to favorites.
   */
  @action
  public addDealToFavorites = async (userId: number, dealId: number) => {
    try {
      this.isLoading = true;

      const response = await post(
        `${WemRoutes.deals}/${userId}/deals/${dealId}`,
        {},
      );

      if (response.ok) {
        let deal = this.deals.find((f) => f.id === dealId);
        if (deal) {
          deal.saved = true;
          this.replaceADeal(deal);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.isLoading = false;
    }
  };

  /**
   * Removes a deal from the user's favorites.
   * @param userId the id of the current logged in user.
   * @param dealId the id of the deal to remove from favorites.
   */
  @action
  public removeDealFromFavorites = async (userId: number, dealId: number) => {
    try {
      this.isLoading = true;

      const response = await remove(
        `${WemRoutes.deals}/${userId}/deals/${dealId}`,
      );

      if (response.ok) {
        let deal = this.deals.find((f) => f.id === dealId);
        if (deal) {
          deal.saved = false;
          this.replaceADeal(deal);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.isLoading = false;
    }
  };

  /**
   * Updates the filterByFavorites variable.
   * */
  @action toggleFavorites = () =>
    (this.filterByFavorites = !this.filterByFavorites);

  /**
   * Returns a deal based on the provided deal id.
   * @param dealId the id of the deal
   * */
  public getDealById = (dealId: number): DealModel | undefined => {
    return this.deals.find((f) => f.id === dealId);
  };

  /**
   * Returns the expiry date for a deal
   * @param dealId the id of the deal
   * */
  public getExpiryDate = (dealId: number): string => {
    const deal = this.getDealById(dealId);
    return deal?.expiry
      ? `Valid Until ${format(new Date(deal.expiry), 'MMMM dd, yyyy')}`
      : '';
  };

  /**
   * Returns deals based on the user filter selection, or all if no filters are
   * set.
   * */
  public getFilteredDeals = (): DealModel[] => {
    // Return the user favorites/saved if filterByFavorites is set to true
    if (this.filterByFavorites) {
      return this.deals.filter((deal) => deal.saved);
    } else {
      // If we are filtering by categories, check if we have filters first
      // otherwise, just return all the deals
      if (this.filteredCategories.length > 0) {
        return this.deals.filter((deal) => {
          let isInCategory = this.filteredCategories.some((category) =>
            deal.categories.includes(category.id),
          );
          return isInCategory ? deal : null;
        });
      } else {
        return this.deals;
      }
    }
  };

  /**
   * Helper function that replaces a deal in the deals array with another of the same id.
   * @param newDeal the deal which will replace another
   */
  @action
  private replaceADeal = (newDeal: DealModel) => {
    this.deals = this.deals.map((deal) =>
      deal.id === newDeal.id ? newDeal : deal,
    );
  };
}

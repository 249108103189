import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const LoyaltyActivityStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: '80px',
      marginBottom: '80px',
      flexGrow: 1,
    },
    historyList: {
      width: '100%',
      overflowY: 'auto',
    },
    inline: {
      display: 'inline',
    },
    avatar: {
      backgroundColor: 'transparent',
    },
  }),
);

export default LoyaltyActivityStyles;

import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography } from '@material-ui/core';

interface ConfirmRedeemDialogProps {
  title: string;
  message: string;
  open: boolean;
  onCancel: () => void;
  onOk: () => void;
}

const ConfirmRedeemDialog: React.FC<ConfirmRedeemDialogProps> = ({
  title,
  message,
  open,
  onCancel,
  onOk,
}) => {
  return (
    <Dialog open={open} onClose={onCancel} aria-labelledby={title}>
      <DialogTitle id="title">
        <Typography component={'span'}>{title}</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography component={'span'} variant={'body1'}>
            {message}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onCancel} color="secondary">
          Cancel
        </Button>
        <Button onClick={onOk} color="secondary" autoFocus>
          Redeem
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmRedeemDialog;

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const FrequentlyAskedQuestionsStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: '80px',
      marginBottom: '80px',
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }),
);

export default FrequentlyAskedQuestionsStyles;

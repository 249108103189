import React from 'react';
import { history } from '../../../index';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { IconButton } from '@material-ui/core';

interface AppToolBarBackButtonProps {
  classes: Record<'menuButton', string>;
  backButtonActionOrUrl?: Function | string;
}

const AppToolBarBackButton: React.FC<AppToolBarBackButtonProps> = ({
  classes,
  backButtonActionOrUrl,
}) => {
  return (
    <IconButton
      edge="start"
      className={classes.menuButton}
      color="inherit"
      aria-label="menu"
      onClick={() => {
        if (typeof backButtonActionOrUrl === 'function') {
          backButtonActionOrUrl();
        } else {
          history.push(backButtonActionOrUrl || '');
        }
      }}
    >
      <ArrowBackIcon />
    </IconButton>
  );
};

export default AppToolBarBackButton;

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const LoyaltyCardStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: 500,
      height: 280,
      borderRadius: 15,
    },
    info: {
      display: 'flex',
      width: '100%',
      padding: theme.spacing(1),
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    barcodeContainer: {
      display: 'flex',
      height: 'inherit',
      justifyContent: 'center',
      alignItems: 'center',
    },
    titleColor: {
      color: theme.palette.primary.main,
    },
  }),
);

export default LoyaltyCardStyles;

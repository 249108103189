import { action, observable } from 'mobx';
import BaseStore from './BaseStore';
import { get } from '../../common/network/agent';
import WemRoutes from '../../common/config/routes/wem-routes';
import PromotionResponseModel from '../../common/models/loyalty/promotion/PromotionResponseModel';

export default class PromotionStore extends BaseStore {
  @observable data: PromotionResponseModel | null = null;

  @action getPromotions = async () => {
    try {
      this.isLoading = true;
      const response = await get(`${WemRoutes.content}`);
      if (response.ok) {
        this.data = (await response.json()) as PromotionResponseModel;
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  };
}

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const TopAppbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      background: theme.palette.primary.main,
      width: 'inherit',
    },
    transparentAppBar: {
      background: 'transparent',
      boxShadow: 'none',
    },
    inputRoot: {
      color: 'inherit',
    },
    avatar: {
      height: 70,
      width: 70,
    },
    avatarImage: {
      height: 'inherit',
      width: 'inherit',
    },
  }),
);

export default TopAppbarStyles;

import React, { Fragment, useContext } from 'react';
import { Grid } from '@material-ui/core';
import DealCard from './DealCard';
import Stores from '../../../domain/stores/Stores';
import { observer } from 'mobx-react-lite';

const DealContent: React.FC = () => {
  const { dealsStore, userStore } = useContext(Stores);
  return (
    <Fragment>
      {dealsStore.getFilteredDeals().map((deal) => {
        return (
          <Grid
            container
            item
            xs={12}
            sm={4}
            md={4}
            lg={4}
            xl={4}
            key={deal.id}
            justify={'center'}
          >
            <DealCard
              dealId={deal.id}
              isFavorite={deal.saved}
              expiry={dealsStore.getExpiryDate(deal.id)}
              tileImage={deal.tileImage}
              storeName={deal.storeName}
              storeIcon={deal.storeIcon}
              title={deal.title}
              onFavoriteButtonClick={async () => {
                const userid = userStore.getUserId();
                if (userid) {
                  if (deal.saved) {
                    await dealsStore.removeDealFromFavorites(userid, deal.id);
                  } else {
                    await dealsStore.addDealToFavorites(userid, deal.id);
                  }
                }
              }}
            />
          </Grid>
        );
      })}
    </Fragment>
  );
};

export default observer(DealContent);

import { action, observable } from 'mobx';
import React from 'react';

/**
 * Observable class used in the LoyaltyActivity component to support maintaining
 * the selected tab index when the user switches to a different page.
 * */
export default class LoyaltyActivityStore {
  @observable selectedTabIndex: number = 0;

  @action handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.selectedTabIndex = newValue;
  };

  @action handleChangeIndex = (index: number) => {
    this.selectedTabIndex = index;
  };
}

import React, { Dispatch, SetStateAction, Fragment, useState } from 'react';
import Notification from '../notifications';
import { DialogType } from '../../../common/enums/DialogType';
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';

interface ScanLoyaltyDialogProps {
  open: boolean;
  initialCheckboxValue: boolean;
  onCancel: Dispatch<SetStateAction<boolean>>;
  onOk: (hideReceiptDialog: boolean) => void;
}

const ScanLoyaltyDialog: React.FC<ScanLoyaltyDialogProps> = (props) => {
  const [hideLoyaltyScanDialog, setHideLoyaltyScanDialog] = useState(
    props.initialCheckboxValue,
  );
  return (
    <Notification
      Type={DialogType.Info}
      Title={'Loyalty ID Usage'}
      Message={
        <Fragment>
          <Typography variant={'body1'} gutterBottom={true}>
            Scan your loyalty ID at the following parks & attractions to earn
            points:
          </Typography>
          <Typography component={'span'} variant={'body1'} gutterBottom={true}>
            <ul>
              <li>Galaxyland</li>
              <li>World Waterpark</li>
              <li>Marine Life</li>
              <li>Ice Palace</li>
              <li>Professor Wem's Adventure Golf</li>
              <li>Dragon's Tale</li>
            </ul>
          </Typography>
          <Typography variant={'body1'} gutterBottom={true}>
            You can also use your loyalty ID for all parks & attractions
            purchased at{' '}
            <a
              href={'https://tickets.wem.ca/'}
              target={'_blank'}
              rel="noopener noreferrer"
            >
              tickets.wem.ca
            </a>
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                color={'primary'}
                checked={hideLoyaltyScanDialog}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setHideLoyaltyScanDialog(event.target.checked);
                }}
              />
            }
            label="Do not show this message again."
          />
        </Fragment>
      }
      Open={props.open}
      OnClose={() => props.onOk(hideLoyaltyScanDialog)}
    />
  );
};

export default ScanLoyaltyDialog;

import React, { Fragment } from 'react';
import LoyaltyActivityStyles from './LoyaltyActivityStyles';
import {
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import PointRecordModel from '../../../common/models/loyalty/point/PointRecordModel';

interface IHistoryProps {
  pointRecords: PointRecordModel[];
}

const History: React.FC<IHistoryProps> = ({ pointRecords }) => {
  const classes = LoyaltyActivityStyles();

  return (
    <List className={classes.historyList}>
      {pointRecords.map((record, groupIndex) => {
        const currentDate = new Date(record.DateCreated).toLocaleString(
          'default',
          {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
          },
        );
        return (
          <Fragment key={record.PointAuditID}>
            <ListItem alignItems={'flex-start'}>
              <ListItemText
                primary={
                  <Typography component={'span'} color={'secondary'}>
                    {record.PointActivityName}
                  </Typography>
                }
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      className={classes.inline}
                      color="textPrimary"
                    >
                      {currentDate}
                    </Typography>
                    {` — You have been awarded with ${record.PointsAwarded.toLocaleString(
                      'en',
                    )} point(s).`}
                  </React.Fragment>
                }
              />
            </ListItem>
            <Divider component="li" />
          </Fragment>
        );
      })}
    </List>
  );
};

export default History;

import { createContext } from 'react';
import PromotionStore from './PromotionStore';
import AuthStore from './AuthStore';
import BaseStore from './BaseStore';
import UserStore from './UserStore';
import PointStore from './loyalty/PointStore';
import ReceiptStore from './loyalty/ReceiptStore';
import RewardStore from './loyalty/reward-store';
import SupportStore from './loyalty/support-store';
import GlobalStore from './GlobalStore';
import LoyaltyCodeStore from './loyalty/LoyaltyCodeStore';
import LoyaltyActivityStore from './LoyaltyActivityStore';
import DealsStore from './loyalty/DealsStore';

const globalStore = new GlobalStore();
const authStore = new AuthStore(globalStore);
const userStore = new UserStore(globalStore, authStore);
const promotionStore = new PromotionStore();
const pointStore = new PointStore();
const receiptStore = new ReceiptStore(userStore, globalStore);
const rewardStore = new RewardStore(globalStore, userStore);
const supportStore = new SupportStore(globalStore);
const loyaltyCodeStore = new LoyaltyCodeStore(userStore, globalStore);
const loyaltyActivityStore = new LoyaltyActivityStore();
const dealsStore = new DealsStore();
const baseStore = new BaseStore();

export const Stores = {
  promotionStore,
  authStore,
  userStore,
  baseStore,
  pointStore,
  receiptStore,
  rewardStore,
  supportStore,
  globalStore,
  loyaltyCodeStore,
  loyaltyActivityStore,
  dealsStore,
};

export default createContext(Stores);

import React, { Fragment, useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Stores from '../../../domain/stores/Stores';
import {
  Container,
  GridList,
  GridListTile,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { history } from '../../../index';
import RewardStyles from './RewardStyles';
import BottomAppBar from '../bottom-appbar';
import clsx from 'clsx';
import TopAppbar from '../top-appbar';

const Reward: React.FC = () => {
  const { rewardStore } = useContext(Stores);
  const classes = RewardStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  });

  useEffect(() => {
    (async () => await rewardStore.getRewards())();
  }, [rewardStore]);

  return (
    <Fragment>
      <TopAppbar showUserIcon={true} showPromoCodeIcon={true} />
      <Container maxWidth={'lg'} className={classes.root}>
        <GridList
          cellHeight={250}
          classes={{ root: classes.gridList }}
          spacing={10}
        >
          <GridListTile key="Subheader" cols={2} style={{ height: 'auto' }}>
            <Select
              value={rewardStore.categoryId}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                rewardStore.categoryId = event.target.value as number;
              }}
              variant={'outlined'}
              fullWidth={true}
            >
              <MenuItem key={-1} value={-1}>
                All
              </MenuItem>
              {rewardStore.rewardsCategories?.Data.map((category) => {
                return (
                  <MenuItem
                    key={category.RewardCategoryID}
                    value={category.RewardCategoryID}
                  >
                    {category.RewardCategoryName}
                  </MenuItem>
                );
              })}
            </Select>
          </GridListTile>
          {rewardStore.getRewardsByCategory().map((rewards) => {
            let reward = rewards[0];
            let costInPoints = reward.CostInPoints.toString();
            if (rewards.length > 1) {
              const min = Math.min.apply(
                Math,
                rewards.map((f) => {
                  return f.CostInPoints;
                }),
              );
              const max = Math.max.apply(
                Math,
                rewards.map((f) => {
                  return f.CostInPoints;
                }),
              );
              costInPoints = min === max ? max.toString() : `${min} - ${max}`;
            }
            return (
              <GridListTile
                key={reward.RewardID}
                onClick={() => {
                  if (reward.QuantityRemaining > 0) {
                    history.push(`/rewards/${reward.LoyaltyProductID}`);
                  }
                }}
                className={classes.gridListTile}
                cols={isMobile ? 2 : 1}
              >
                <img
                  src={reward.GridImage}
                  alt={reward.Title}
                  loading={'lazy'}
                />
                <div
                  className={clsx(
                    classes.caption,
                    reward.QuantityRemaining === 0
                      ? classes.soldOutItemBackground
                      : classes.availableItemBackground,
                  )}
                >
                  {reward.QuantityRemaining === 0 ? (
                    <Typography align={'center'} component={'h6'}>
                      SOLD OUT
                    </Typography>
                  ) : (
                    <div className={classes.gridListTileBar}>
                      <Typography
                        align={'left'}
                        variant={'subtitle1'}
                        noWrap={false}
                      >
                        {reward.Title}
                      </Typography>
                      <Typography variant={'caption'}>
                        <strong>{costInPoints} points</strong>
                      </Typography>
                    </div>
                  )}
                </div>
              </GridListTile>
            );
          })}
        </GridList>
      </Container>
      <BottomAppBar />
    </Fragment>
  );
};

export default observer(Reward);

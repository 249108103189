import React, { useContext } from 'react';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import LoadingStyles from './LoadingStyles';
import Stores from '../../../domain/stores/Stores';

const Loading: React.FC = () => {
  const {
    authStore,
    receiptStore,
    userStore,
    pointStore,
    promotionStore,
    rewardStore,
    supportStore,
    loyaltyCodeStore,
    dealsStore,
  } = useContext(Stores);
  const classes = LoadingStyles();
  return (
    <Backdrop
      className={classes.backdrop}
      open={
        authStore.isLoading ||
        receiptStore.isLoading ||
        userStore.isLoading ||
        pointStore.isLoading ||
        promotionStore.isLoading ||
        rewardStore.isLoading ||
        supportStore.isLoading ||
        loyaltyCodeStore.isLoading ||
        dealsStore.isLoading
      }
    >
      <CircularProgress />
    </Backdrop>
  );
};

export default observer(Loading);

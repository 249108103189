import React, { Dispatch, SetStateAction, useContext } from 'react';
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  List,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { TransitionUp } from '../../../common/utils/Transitions';
import CloseIcon from '@material-ui/icons/Close';
import DealStyles from './DealStyles';
import DealCategories from './DealCategories';
import Stores from '../../../domain/stores/Stores';
import { observer } from 'mobx-react-lite';
import DealCategoryModel from '../../../common/models/loyalty/deals/DealCategoryModel';

interface FilterDialogProps {
  open: boolean;
  onClose: () => void;
  currentFilters: DealCategoryModel[];
  setCurrentFilters: Dispatch<SetStateAction<DealCategoryModel[]>>;
}

const DealFilterDialog: React.FC<FilterDialogProps> = ({
  open,
  onClose,
  currentFilters,
  setCurrentFilters,
}) => {
  const { dealsStore } = useContext(Stores);
  const classes = DealStyles();
  return (
    <Dialog open={open} TransitionComponent={TransitionUp} fullScreen={true}>
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6">FILTERS</Typography>
          <Button
            autoFocus
            color="inherit"
            onClick={() => {
              setCurrentFilters([]);
            }}
          >
            RESET
          </Button>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <List>
          <DealCategories
            categories={dealsStore.categories}
            currentFilters={currentFilters}
            updateCategoryFilters={setCurrentFilters}
          />
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth={true}
          variant={'contained'}
          size={'large'}
          color={'secondary'}
          onClick={() => {
            dealsStore.applyFilters(currentFilters);
            onClose();
          }}
        >
          APPLY FILTERS
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default observer(DealFilterDialog);

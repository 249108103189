import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const CopyrightStyles = makeStyles((theme: Theme) =>
  createStyles({
    textColor: {
      color: '#69686d',
    },
  }),
);

export default CopyrightStyles;

import React, { Fragment } from 'react';
import { CssBaseline } from '@material-ui/core';
import AppStyles from './AppStyles';
import { Redirect, Route, Switch } from 'react-router-dom';
import Landing from './components/landing';
import SignUp from './components/sign-up';
import SignIn from './components/sign-in';
import Profile from './components/profile';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import AuthenticatedRoute from './hoc/authenticated-route';
import { HowToEarn, HowToEarnDetail } from './components/how-to-earn';
import { Reward, RewardDetail } from './components/rewards';
import Wrapper from './components/wrapper';
import ScanLoyalty from './components/scan-loyalty';
import { Promotion, PromotionDetail } from './components/promotion';
import Privacy from './components/privacy';
import Terms from './components/terms';
import FrequentlyAskedQuestions from './components/frequently-asked-questions';
import ContactSupport from './components/contact-support';
import ForgotPassword from './components/forgot-password';
import LoyaltyActivity from './components/loyalty-activity';
import LoyaltyActivityScanDetail from './components/loyalty-activity/LoyaltyActivityScanDetail';
import LoyaltyCode from './components/loyalty-code';
import NotFound from './components/not-found';
import Deals from './components/deals';
import DealDetail from './components/deals/DealDetail';

const App: React.FC = () => {
  const classes = AppStyles();

  return (
    <Fragment>
      <CssBaseline />
      <div className={classes.root}>
        <Wrapper>
          <Switch>
            <Redirect exact={true} from={'/'} to={'/landing'} />
            <Route path={'/landing'} component={Landing} />

            <AuthenticatedRoute path={'/loyaltycode'} component={LoyaltyCode} />

            <AuthenticatedRoute path={'/profile'} component={Profile} />

            <AuthenticatedRoute path={'/scan'} component={ScanLoyalty} />

            <AuthenticatedRoute
              path={'/faqs'}
              component={FrequentlyAskedQuestions}
            />

            <Route
              path={'/deals'}
              render={({ match: { url } }) => (
                <>
                  <AuthenticatedRoute
                    path={`${url}/`}
                    component={Deals}
                    exact
                  />
                  <AuthenticatedRoute
                    path={`${url}/:dealId`}
                    component={DealDetail}
                  />
                </>
              )}
            />

            <Route
              path={'/activity'}
              render={({ match: { url } }) => (
                <>
                  <AuthenticatedRoute
                    path={`${url}/`}
                    component={LoyaltyActivity}
                    exact
                  />
                  <AuthenticatedRoute
                    path={`${url}/scan/:id`}
                    component={LoyaltyActivityScanDetail}
                  />
                </>
              )}
            />

            <Route
              path={'/promotions'}
              render={({ match: { url } }) => (
                <>
                  <AuthenticatedRoute
                    path={`${url}/`}
                    component={Promotion}
                    exact
                  />
                  <AuthenticatedRoute
                    path={`${url}/:id`}
                    component={PromotionDetail}
                  />
                </>
              )}
            />

            <Route
              path={'/rewards'}
              render={({ match: { url } }) => (
                <>
                  <AuthenticatedRoute
                    path={`${url}/`}
                    component={Reward}
                    exact
                  />
                  <AuthenticatedRoute
                    path={`${url}/:id`}
                    component={RewardDetail}
                  />
                </>
              )}
            />

            <Route component={NotFound} />
          </Switch>
        </Wrapper>
      </div>
    </Fragment>
  );
};

export default App;

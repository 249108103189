import React, { useContext } from 'react';
import { withRouter, RouteComponentProps, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import Stores from '../../../domain/stores/Stores';
import { history } from '../../../index';
import DetailPage from '../detail-page';
import HeroImageModel from '../../../common/models/app/HeroImageModel';

const PromotionDetail: React.FC<RouteComponentProps<{ id: string }>> = (
  props,
) => {
  const { promotionStore } = useContext(Stores);
  const promotion = promotionStore.data?.promotions?.find(
    (f) => f.id.toString() === props.match.params.id,
  );
  return promotion ? (
    <DetailPage
      title={promotion?.title.toUpperCase()}
      backButtonAction={() => history.goBack()}
      HeroImage={new HeroImageModel(promotion?.heroImage, promotion?.title)}
      CaptionText={promotion?.title.toUpperCase()}
      BodyContent={promotion?.caption}
      Links={promotion?.links}
    />
  ) : (
    <Redirect to={'/notfound'} />
  );
};

export default withRouter(observer(PromotionDetail));

import React, { useContext, useState } from 'react';
import {
  BottomNavigation,
  BottomNavigationAction,
  Typography,
} from '@material-ui/core';
import BottomAppbarStyles from './BottomAppbarStyles';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import HistoryIcon from '@material-ui/icons/History';
import RedeemIcon from '@material-ui/icons/Redeem';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { history } from '../../../index';
import Stores from '../../../domain/stores/Stores';

const BottomAppBar: React.FC<RouteComponentProps> = (props) => {
  const classes = BottomAppbarStyles();
  const { globalStore } = useContext(Stores);
  return (
    <BottomNavigation
      value={globalStore.routeValue}
      className={classes.root}
      showLabels={true}
    >
      <BottomNavigationAction
        onClick={() => history.push('/promotions')}
        icon={<HomeOutlinedIcon />}
        label={<Typography variant={'caption'}>Home</Typography>}
        aria-label={'Home'}
      />

      <BottomNavigationAction
        icon={<HistoryIcon />}
        onClick={() => history.push('/activity')}
        label={<Typography variant={'caption'}>History</Typography>}
        aria-label={'History'}
      />
      <BottomNavigationAction
        icon={<RedeemIcon />}
        onClick={() => history.push('/rewards')}
        label={<Typography variant={'caption'}>Redeem</Typography>}
        aria-label={'Redeem'}
      />
    </BottomNavigation>
  );
};

export default withRouter(observer(BottomAppBar));

import React, { Fragment } from 'react';
import PromoCodeModel from '../../../common/models/loyalty/deals/PromoCodeModel';
import { Typography } from '@material-ui/core';

interface DealPromoCodeProps {
  promoCode?: PromoCodeModel | null;
}

const DealPromoCode: React.FC<DealPromoCodeProps> = ({ promoCode }) => {
  return (
    <Fragment>
      {promoCode?.type === 'url' ? (
        <img src={promoCode?.value} alt={'Promo Code'} width={'100%'} />
      ) : (
        <Typography variant={'h4'}>{promoCode?.value}</Typography>
      )}
    </Fragment>
  );
};

export default DealPromoCode;

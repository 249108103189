import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const LoadingStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 999999,
      color: '#fff',
    },
  }),
);

export default LoadingStyles;

import React, { Fragment, useContext, useEffect } from 'react';
import BottomAppBar from '../bottom-appbar';
import {
  Accordion,
  Container,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from '@material-ui/core';
import FrequentlyAskedQuestionsStyles from './FrequentlyAskedQuestionsStyles';
import { observer } from 'mobx-react-lite';
import Stores from '../../../domain/stores/Stores';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TopAppbar from '../top-appbar';

const FrequentlyAskedQuestions: React.FC = () => {
  const { supportStore } = useContext(Stores);
  const classes = FrequentlyAskedQuestionsStyles();

  useEffect(() => {
    (async () => await supportStore.getFrequentlyAskedQuestions())();
  }, [supportStore]);

  return (
    <Fragment>
      <TopAppbar showUserIcon={true} showPromoCodeIcon={true} />
      <Container maxWidth={'md'} className={classes.root}>
        <Fragment>
          {supportStore.frequentlyAskedQuestions
            ? supportStore.frequentlyAskedQuestions.map((faq) => {
                return (
                  <Accordion key={faq.Title}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography component={'h6'} variant={'subtitle1'}>
                        {faq.Title}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography component={'p'} variant={'body1'}>
                        {faq.Description}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                );
              })
            : null}
        </Fragment>
      </Container>
      <BottomAppBar />
    </Fragment>
  );
};

export default observer(FrequentlyAskedQuestions);

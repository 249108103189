import { history } from '../../index';
import LoyaltyTokenResponseModel from '../models/auth/LoyaltyTokenResponseModel';

/**
 * Since we are using two types of authentication cookie/bearer token, we need
 * to make sure we include the token if it is available. We also need to check
 * if the current request is uploading a file or regular form data. Depending
 * on the request we either append application/json for form data or omit it
 * if it is a file upload.
 * @param headers optional headers passed in
 * @param isFileUpload flag to determine if the request is for a file upload
 * */
const getHeaders = (headers?: Headers, isFileUpload: boolean = false) => {
  let newHeaders = headers ? headers : new Headers();
  const skiDataToken = localStorage.getItem('skiDataToken');
  if (skiDataToken) {
    const token = JSON.parse(skiDataToken) as LoyaltyTokenResponseModel;
    newHeaders.append('Authorization', `${token.loyaltyToken?.accessToken}`);
  }

  if (!headers && !isFileUpload) {
    newHeaders.append('Content-Type', 'application/json');
  }

  return newHeaders;
};

const post = async (
  url: string,
  payload: any,
  headers?: Headers,
): Promise<Response> => {
  if (navigator.onLine) {
    try {
      const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        headers: getHeaders(headers),
        body: JSON.stringify(payload),
      });

      // If we receive an unauthorized response, we kick the user back to the login
      // page
      if (response.status === 401 && history.location.pathname !== '/signin') {
        history.push('/landing');
      } else if (response.status >= 500) {
        const data = await response.json();
        return Promise.reject(data.Message);
      }

      return response;
    } catch (e) {
      // If for some reason the user is not able to communicate with the server,
      // i.e. server is down for maintenance.
      return Promise.reject(
        'An error occurred while trying to communicate with the server.',
      );
    }
  } else {
    // Happens if the user is offline.
    return Promise.reject(
      'Please check your internet connection and try again.',
    );
  }
};

const get = async (url: string): Promise<Response> => {
  if (navigator.onLine) {
    try {
      const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        headers: getHeaders(),
      });

      if (response.status === 401) {
        history.push('/landing');
      } else if (response.status >= 500) {
        const data = await response.json();
        return Promise.reject(data.Message);
      }

      return response;
    } catch (e) {
      return Promise.reject(
        'An error occurred while trying to communicate with the server.',
      );
    }
  } else {
    return Promise.reject(
      'Please check your internet connection and try again.',
    );
  }
};

const put = async (url: string, payload: any): Promise<Response> => {
  if (navigator.onLine) {
    try {
      const response = await fetch(url, {
        method: 'PUT',
        mode: 'cors',
        headers: getHeaders(),
        body: JSON.stringify(payload),
      });

      if (response.status === 401) {
        history.push('/landing');
      } else if (response.status >= 500) {
        const data = await response.json();
        return Promise.reject(data.Message);
      }

      return response;
    } catch (e) {
      return Promise.reject(
        'An error occurred while trying to communicate with the server.',
      );
    }
  } else {
    return Promise.reject(
      'Please check your internet connection and try again.',
    );
  }
};

const remove = async (url: string): Promise<Response> => {
  if (navigator.onLine) {
    try {
      const response = await fetch(url, {
        method: 'DELETE',
        mode: 'cors',
        headers: getHeaders(),
      });

      if (response.status === 401) {
        history.push('/landing');
      } else if (response.status >= 500) {
        const data = await response.json();
        return Promise.reject(data.Message);
      }

      return response;
    } catch (e) {
      return Promise.reject(
        'An error occurred while trying to communicate with the server.',
      );
    }
  } else {
    return Promise.reject(
      'Please check your internet connection and try again.',
    );
  }
};

/**
 * Handles file uploads.
 * @param url the requested endpoint
 * @param data the form data which contains the file(s)
 * */
const fileUpload = async (url: string, data: FormData): Promise<Response> => {
  if (navigator.onLine) {
    try {
      const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        body: data,
        headers: getHeaders(undefined, true),
      });

      if (response.status === 401) {
        history.push('/landing');
      } else if (response.status >= 500) {
        const data = await response.json();
        return Promise.reject(data.Message);
      }

      return response;
    } catch (e) {
      return Promise.reject(
        'An error occurred while trying to communicate with the server.',
      );
    }
  } else {
    return Promise.reject(
      'Please check your internet connection and try again.',
    );
  }
};

export { post, get, put, remove, fileUpload };

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const LoyaltyCodeStyles = makeStyles((theme: Theme) =>
  createStyles({
    formContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
    },
    icon: {
      width: 80,
      height: 80,
      color: theme.palette.primary.main,
    },
  }),
);

export default LoyaltyCodeStyles;

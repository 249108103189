import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const DetailPageStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(7),
        marginBottom: theme.spacing(10),
        width: '100%',
      },
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(10),
        width: '50%',
        alignSelf: 'center',
      },
    },
    image: {
      width: '100%',
      marginBottom: theme.spacing(3),
    },
    textMargins: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    linkButtonWhiteBackground: {
      borderRadius: 8,
      minWidth: 250,
      minHeight: 65,
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.secondary.main,
    },
    linkButtonGreenBackground: {
      borderRadius: 8,
      minWidth: 250,
      minHeight: 65,
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.background.paper,
    },
  }),
);

export default DetailPageStyles;

import React, { Fragment, useContext, useState } from 'react';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import Stores from '../../../domain/stores/Stores';
import DetailPage from '../detail-page';
import { history } from '../../../index';
import HeroImageModel from '../../../common/models/app/HeroImageModel';
import { Grid, Typography } from '@material-ui/core';
import DealStyles from './DealStyles';
import RedeemDealButton from './RedeemDealButton';
import DealPromoCode from './DealPromoCode';
import { observer } from 'mobx-react-lite';
import ConfirmRedeemDialog from './ConfirmRedeemDialog';

const DealDetail: React.FC<RouteComponentProps<{ dealId: string }>> = (
  props,
) => {
  const { dealsStore, userStore } = useContext(Stores);
  const classes = DealStyles();
  const deal = dealsStore.getDealById(parseInt(props.match.params.dealId));
  const [dealRedeemed, setDealRedeemed] = useState(deal?.redeemed || false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  return deal ? (
    <Fragment>
      <DetailPage
        title={deal.title || 'DEAL DETAIL'}
        backButtonAction={() => history.goBack()}
        HeroImage={new HeroImageModel(deal?.heroImage, deal?.title)}
        CaptionText={deal?.title.toUpperCase()}
        BodyContent={
          <Fragment>
            <Typography
              component={'div'}
              variant={'body1'}
              gutterBottom={true}
              className={classes.textMargins}
            >
              <p dangerouslySetInnerHTML={{ __html: deal.caption }} />
              <p dangerouslySetInnerHTML={{ __html: deal.terms }} />
            </Typography>
            <Grid container item xs={12} justify={'center'}>
              {dealRedeemed ? (
                <DealPromoCode promoCode={deal?.promocode} />
              ) : (
                <RedeemDealButton
                  title={deal.title}
                  showConfirmDialog={setConfirmDialogOpen}
                />
              )}
            </Grid>
          </Fragment>
        }
        Links={deal?.links}
        externalLinkTagType={'Deal'}
        LinkButtonStyleVariant={true}
      />
      <ConfirmRedeemDialog
        title={'USE IN STORE ONLY'}
        message={
          'This deal can only be used in store. Please confirm you are in store to redeem this one time use deal.'
        }
        open={confirmDialogOpen}
        onCancel={() => setConfirmDialogOpen(false)}
        onOk={async () => {
          setDealRedeemed(true);
          setConfirmDialogOpen(false);
          const userId = userStore.getUserId();
          if (userId) {
            await dealsStore.redeemDeal(userId, deal?.id);
          }
        }}
      />
    </Fragment>
  ) : (
    <Redirect to={'/notfound'} />
  );
};

export default withRouter(observer(DealDetail));

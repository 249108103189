import React, { Dispatch, ReactNode, SetStateAction, useContext } from 'react';
import { Toolbar, Typography } from '@material-ui/core';
import AppToolBarStyles from './AppToolBarStyles';
import AppToolBarActionRow from './AppToolBarActionRow';
import AppToolBarBackButton from './AppToolBarBackButton';
import AppHamburgerButton from './AppHamburgerButton';
import { observer } from 'mobx-react-lite';
import Stores from '../../../domain/stores/Stores';

interface AppToolBarProps {
  backButtonActionOrUrl?: Function | string;
  setDrawerOpen: Dispatch<SetStateAction<boolean>>;
  title?: string;
  showPromoCodeIcon?: boolean;
  showUserIcon?: boolean;
  actionButton?: ReactNode;
}

const AppToolBar: React.FC<AppToolBarProps> = (props) => {
  const classes = AppToolBarStyles();
  const { userStore } = useContext(Stores);
  return (
    <Toolbar className={classes.root}>
      <div className={classes.pointsContainer}>
        {props.backButtonActionOrUrl ? (
          <AppToolBarBackButton
            classes={classes}
            backButtonActionOrUrl={props.backButtonActionOrUrl}
          />
        ) : (
          <AppHamburgerButton
            setDrawerOpen={props.setDrawerOpen}
            classes={classes}
          />
        )}
        {userStore.userModel?.Data.CurrentPoints?.PointsRemaining && (
          <Typography noWrap>
            {userStore.userModel?.Data.CurrentPoints?.PointsRemaining || 0}{' '}
            POINTS
          </Typography>
        )}
      </div>
      {props.title && (
        <Typography variant={'h6'} noWrap>
          {props?.title?.toLocaleUpperCase()}
        </Typography>
      )}
      <AppToolBarActionRow
        showPromoCodeIcon={props.showPromoCodeIcon}
        showUserIcon={props.showUserIcon}
        actionButton={props.actionButton}
      />
    </Toolbar>
  );
};

export default observer(AppToolBar);

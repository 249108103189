import React, { useContext, Fragment } from 'react';
import { observer } from 'mobx-react-lite';
import { Container } from '@material-ui/core';
import Stores from '../../../domain/stores/Stores';
import { Formik } from 'formik';
import LoyaltyCodeSchema from './LoyaltyCodeSchema';
import LoyaltyCodeStyles from './LoyaltyCodeStyles';
import BottomAppBar from '../bottom-appbar';
import LoyaltyCodeForm from './LoyaltyCodeForm';
import LoyaltyCodeFormModel from '../../../common/models/loyalty/code/LoyaltyCodeFormModel';
import TopAppbar from '../top-appbar';

const LoyaltyCode: React.FC = () => {
  const { loyaltyCodeStore } = useContext(Stores);
  const classes = LoyaltyCodeStyles();

  return (
    <Fragment>
      <TopAppbar showUserIcon={true} />
      <Formik
        initialValues={new LoyaltyCodeFormModel()}
        validationSchema={LoyaltyCodeSchema}
        onSubmit={async (values) => {
          await loyaltyCodeStore.applyLoyaltyCode(values.loyaltyCode);
        }}
      >
        {(formikProps) => (
          <Container
            component={'main'}
            maxWidth={'sm'}
            className={classes.formContainer}
          >
            <div className={classes.paper}>
              <LoyaltyCodeForm formikProps={formikProps} classes={classes} />
            </div>
          </Container>
        )}
      </Formik>
      <BottomAppBar />
    </Fragment>
  );
};

export default observer(LoyaltyCode);

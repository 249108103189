import { action } from 'mobx';
import { get, post } from '../../common/network/agent';
import BaseStore from './BaseStore';
import { history } from '../../index';
import GlobalStore from './GlobalStore';
import { DialogType } from '../../common/enums/DialogType';
import SkiDataRoutes from '../../common/config/routes/skidata-routes';
import LoyaltyTokenResponseModel from '../../common/models/auth/LoyaltyTokenResponseModel';
import FacebookAuthModel from '../../common/models/social/facebook/FacebookAuthModel';
import FacebookResponseModel from '../../common/models/social/facebook/FacebookResponseModel';
import { CONNECTED } from '../../common/constants/facebook_constants';

export default class AuthStore extends BaseStore {
  constructor(private globalStore: GlobalStore) {
    super();
  }

  // Given a username get the login type the user registered with
  getLoginType = async (username: string): Promise<number | undefined> => {
    const request = await get(
      `${SkiDataRoutes.authentication.loginType}/${username}`,
    );
    const response = await request.json();
    if (request.ok) {
      return response as number;
    } else {
      throw response;
    }
  };

  getFacebookApplicationId = () =>
    process.env.REACT_APP_FACEBOOK_APPLICATION_ID;

  @action facebookLogin = async (authModel: FacebookAuthModel) => {
    try {
      if (authModel) {
        this.isLoading = true;
        const headers = new Headers();
        headers.append('x-api-key', `${process.env.REACT_APP_SKIDATA_KEY}`);
        headers.append('Accept', 'text/plain');
        headers.append('Content-Type', 'application/json-patch+json');
        const response = await post(
          `${SkiDataRoutes.sso.facebook}/login`,
          JSON.stringify({
            accessToken: authModel.accessToken,
            clientId: `${process.env.REACT_APP_SKIDATA_CLIENT_ID}`,
          }),
          headers,
        );
        if (response.ok) {
          const loyaltyResponse = (await response.json()) as LoyaltyTokenResponseModel;
          localStorage.setItem('skiDataToken', JSON.stringify(loyaltyResponse));
          history.push('/promotions');
        } else {
          this.globalStore.showDialog(
            DialogType.Error,
            'Facebook Login',
            'An error occurred while trying to validate you through Facebook',
          );
        }
      } else {
        this.globalStore.showDialog(
          DialogType.Error,
          'Facebook Login',
          'An error occurred while trying to validate you through Facebook',
        );
      }
    } catch (err) {
      this.globalStore.showDialog(DialogType.Error, 'Sign In', err);
    } finally {
      this.isLoading = false;
    }
  };

  // Authenticates the user with SkiData
  @action validate = async (username: string, password: string) => {
    try {
      this.isLoading = true;
      // Get user login type
      const loginType = await this.getLoginType(username);

      const request = await post(
        `${SkiDataRoutes.authentication.validate}/${username}/${loginType}`,
        JSON.stringify({ credential: password }),
      );

      if (request.ok) {
        history.push('/promotions');
      } else {
        this.globalStore.showDialog(
          DialogType.Error,
          'Sign In',
          'Invalid user name or password',
        );
      }
    } catch (err) {
      this.globalStore.showDialog(DialogType.Error, 'Sign In', err);
    } finally {
      this.isLoading = false;
    }
  };

  resetPassword = async (username: string) => {
    try {
      this.isLoading = true;
      const request = await post(
        `${SkiDataRoutes.authentication.resetPassword}/${username}`,
        null,
      );
      const response = await request.json();
      if (request.ok) {
        this.globalStore.showDialog(
          DialogType.Success,
          'Password Reset',
          `An email with password reset instruction has been sent to ${username}`,
        );
      } else {
        this.globalStore.showDialog(
          DialogType.Error,
          'Password Reset',
          response.Message,
        );
      }
    } catch (err) {
      this.globalStore.showDialog(DialogType.Error, 'Password Reset', err);
    } finally {
      this.isLoading = false;
    }
  };

  @action resetStore = async () => {
    try {
      this.isLoading = true;
      if ((window as any).FB) {
        await new Promise((resolve) =>
          (window as any).FB.getLoginStatus(
            async (response: FacebookResponseModel) => {
              if (response.status === CONNECTED) {
                (window as any).FB.logout();
              }
              resolve();
            },
          ),
        );
      }
      await get(SkiDataRoutes.authentication.logout);
      localStorage.removeItem('skiDataToken');
    } catch (err) {
      this.globalStore.showDialog(DialogType.Error, 'Logout', err);
    } finally {
      this.isLoading = false;
    }
  };

  @action logout = async () => {
    await this.resetStore();
    history.push('/landing');
  };
}

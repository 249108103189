import React, { Fragment, useContext, useEffect, useState } from 'react';
import TopAppbar from '../top-appbar';
import { Button, Container, Grid, MenuItem } from '@material-ui/core';
import DealStyles from './DealStyles';
import FilterListIcon from '@material-ui/icons/FilterList';
import { observer } from 'mobx-react-lite';
import DealsMenu from './DealsMenu';
import Stores from '../../../domain/stores/Stores';
import DealFilterDialog from './DealFilterDialog';
import DealCategoryModel from '../../../common/models/loyalty/deals/DealCategoryModel';
import DealChip from './DealChip';
import DealContent from './DealContent';
import EmptyDeals from './EmptyDeals';

const Deals: React.FC = () => {
  const classes = DealStyles();
  const { dealsStore, userStore } = useContext(Stores);
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
  const [currentCategoryFilters, setCurrentCategoryFilters] = useState<
    DealCategoryModel[]
  >(dealsStore.filteredCategories);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    (async () => {
      await userStore.getUser();
      const userId = userStore.getUserId();
      await dealsStore.getDealCategories();
      if (userId) {
        await dealsStore.getDeals(userId);
      }
    })();
  }, [dealsStore, userStore]);

  return (
    <Fragment>
      <TopAppbar showUserIcon={true} showPromoCodeIcon={true} />
      <Container
        maxWidth={dealsStore.deals.length > 0 ? 'lg' : 'md'}
        className={classes.root}
      >
        {dealsStore.deals.length > 0 ? (
          <Grid container spacing={3}>
            <Grid container item xs={12} justify={'flex-end'}>
              <Button
                variant="text"
                color="secondary"
                className={classes.button}
                endIcon={<FilterListIcon />}
                onClick={handleClick}
              >
                Filter
              </Button>
            </Grid>
            <Grid container item xs={12}>
              <DealChip
                currentCategoryFilters={currentCategoryFilters}
                setCurrentCategoryFilters={setCurrentCategoryFilters}
              />
            </Grid>
            <DealContent />
          </Grid>
        ) : (
          !dealsStore.isLoading && !userStore.isLoading && <EmptyDeals />
        )}
      </Container>
      <DealsMenu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        keepMounted={true}
        onClose={() => setAnchorEl(null)}
        id={'deals-menu'}
      >
        <MenuItem
          key={'categories'}
          onClick={() => {
            setFilterDialogOpen(true);
            setAnchorEl(null);
          }}
        >
          Categories
        </MenuItem>
        <MenuItem
          key={'favorites'}
          onClick={() => {
            dealsStore.toggleFavorites();
            setAnchorEl(null);
          }}
        >
          Favorites
        </MenuItem>
      </DealsMenu>
      <DealFilterDialog
        open={filterDialogOpen}
        onClose={() => setFilterDialogOpen(false)}
        currentFilters={currentCategoryFilters}
        setCurrentFilters={setCurrentCategoryFilters}
      />
    </Fragment>
  );
};

export default observer(Deals);

/**
 * A higher order component, where applicable, and when wrapper around a component provides authentication triggers
 * based on responses received from SkiData.
 * */
import React, { useContext, useEffect } from 'react';
import { Route } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import Stores from '../../../domain/stores/Stores';
import { RouteProps } from 'react-router-dom';

interface AuthenticatedRouteProps extends RouteProps {
  component: any;
}

const AuthenticatedRoute: React.FC<AuthenticatedRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const { userStore } = useContext(Stores);
  useEffect(() => {
    (async () => await userStore.getUser())();
  }, [userStore]);
  return (
    <Route {...rest} render={(routeProps) => <Component {...routeProps} />} />
  );
};

export default observer(AuthenticatedRoute);

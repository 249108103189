import * as Yup from 'yup';
import {
  NAME_EXPRESSION,
  POSTAL_ZIP_EXPRESSION,
} from '../../../common/constants/RegularExpressionConstants';

const ProfileSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .matches(NAME_EXPRESSION, 'Please enter valid first name')
    .required('Please enter your first name'),
  lastName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .matches(NAME_EXPRESSION, 'Please enter valid last name')
    .required('Please enter your last name'),
  postalCode: Yup.string()
    .matches(POSTAL_ZIP_EXPRESSION, 'Please enter a valid postal/zip code')
    .required('Please enter your postal/zip code'),
  birthDate: Yup.date().required('Please enter your birth date'),
  gender: Yup.string().required('Please select a gender'),
});

export default ProfileSchema;

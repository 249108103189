import React, { Fragment } from 'react';
import LoyaltyActivityStyles from './LoyaltyActivityStyles';
import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  ListItemSecondaryAction,
  Divider,
} from '@material-ui/core';
import DetailsIcon from '@material-ui/icons/MoreHoriz';
import { history } from '../../../index';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import ScanRecordModel from '../../../common/models/loyalty/scans/ScanRecordModel';

interface IScansProp {
  scanRecords: ScanRecordModel[];
}

const Scans: React.FC<IScansProp> = ({ scanRecords }) => {
  const classes = LoyaltyActivityStyles();

  return (
    <List className={classes.historyList}>
      {scanRecords
        .sort((a, b) => {
          let timeA = new Date(a.DateCreated).getTime();
          let timeB = new Date(b.DateCreated).getTime();
          return timeB - timeA;
        })
        .map((record, groupIndex) => {
          const currentDate = new Date(record.DateCreated).toLocaleString(
            'default',
            {
              day: 'numeric',
              month: 'long',
              year: 'numeric',
            },
          );
          return (
            <Fragment key={record.ScanID}>
              <ListItem
                alignItems={'flex-start'}
                onClick={() => {
                  history.push(`/activity/scan/${record.ScanID}`);
                }}
              >
                <ListItemAvatar>
                  <Avatar alt={'Receipt'} className={classes.avatar}>
                    <ReceiptOutlinedIcon color={'primary'} />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography
                      component={'span'}
                      color={
                        record.StatusCodeID === 12 || record.StatusCodeID === 2
                          ? 'secondary'
                          : 'textPrimary'
                      }
                    >
                      {record.StatusDescription}
                    </Typography>
                  }
                  secondary={
                    <Typography color={'primary'} gutterBottom={true}>
                      {currentDate}
                    </Typography>
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="details"
                    onClick={() => {
                      history.push(`/activity/scan/${record.ScanID}`);
                    }}
                  >
                    <DetailsIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider variant="inset" component="li" />
            </Fragment>
          );
        })}
    </List>
  );
};

export default Scans;

import BaseStore from '../BaseStore';
import { fileUpload, get } from '../../../common/network/agent';
import { action, observable } from 'mobx';
import UserStore from '../UserStore';
import SkiDataRoutes from '../../../common/config/routes/skidata-routes';
import { DialogType } from '../../../common/enums/DialogType';
import GlobalStore from '../GlobalStore';
import { ChangeEvent } from 'react';
import ScanRecordModel from '../../../common/models/loyalty/scans/ScanRecordModel';
import ScanRecordResponseModel from '../../../common/models/loyalty/scans/ScanRecordResponseModel';

export default class ReceiptStore extends BaseStore {
  localStorageName: string = 'myWemApplicationReceiptDialog';
  successMessage: string =
    'Receipt(s) have been uploaded successfully, and are currently being processed for loyalty points.';
  failMessage: string =
    'Any error occurred while trying to upload your receipt(s). Please try again later.';
  @observable scanRecord: ScanRecordModel[] = [];

  constructor(private userStore: UserStore, private globalStore: GlobalStore) {
    super();
  }

  @action getScanRecords = async () => {
    try {
      this.isLoading = true;
      const request = await get(
        `${SkiDataRoutes.receipt}/${this.userStore.userModel?.Data.PortalID}/${this.userStore.userModel?.Data.UserID}`,
      );
      if (request.ok) {
        this.scanRecord = [];
        const response = (await request.json()) as ScanRecordResponseModel;
        this.scanRecord = response.Data as ScanRecordModel[];
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  };

  getScanReceiptById = (id: number): ScanRecordModel | null => {
    return this.scanRecord.find((f) => f.ScanID === id) || null;
  };

  showReceiptConditionDialog = () =>
    localStorage.getItem(this.localStorageName) !== null &&
    localStorage.getItem(
      this.localStorageName + this.userStore.userModel?.Data.UserID,
    ) === 'true';

  disableShowReceiptDialog = () => {
    localStorage.setItem(
      this.localStorageName + this.userStore.userModel?.Data.UserID,
      'true',
    );
  };

  uploadReceipt = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      this.isLoading = true;
      const target = e.target as HTMLInputElement;
      const files = target.files as FileList;
      if (files.length > 0) {
        let formData = new FormData();
        for (let x = 0; x < files.length; x++) {
          formData.append('receipts[]', files[x]);
        }
        const request = await fileUpload(
          `${SkiDataRoutes.receipt}/${this.userStore.userModel?.Data.UserID}/${this.userStore.userModel?.Data.PortalID}`,
          formData,
        );

        if (request.ok) {
          await this.getScanRecords();
          this.globalStore.showDialog(
            DialogType.Success,
            'Receipt(s) Upload',
            this.successMessage,
          );
        } else {
          this.globalStore.showDialog(
            DialogType.Error,
            'Receipt(s) Upload',
            this.failMessage,
          );
        }
      }
    } catch (err) {
      this.globalStore.showDialog(DialogType.Error, 'Receipt(s) Upload', err);
    } finally {
      this.isLoading = false;
    }
  };
}

import React, { Dispatch, SetStateAction, useContext } from 'react';
import { Chip, Typography } from '@material-ui/core';
import DealStyles from './DealStyles';
import { observer } from 'mobx-react-lite';
import Stores from '../../../domain/stores/Stores';
import DealCategoryModel from '../../../common/models/loyalty/deals/DealCategoryModel';

interface DealChipProps {
  setCurrentCategoryFilters: Dispatch<SetStateAction<DealCategoryModel[]>>;
  currentCategoryFilters: DealCategoryModel[];
}

const DealChip: React.FC<DealChipProps> = ({
  setCurrentCategoryFilters,
  currentCategoryFilters,
}) => {
  const { dealsStore } = useContext(Stores);
  const classes = DealStyles();
  return (
    <div className={classes.dealChipContainer}>
      {dealsStore.filterByFavorites ? (
        <Chip
          key={'favorites'}
          className={classes.chip}
          size={'small'}
          label={<Typography>Favorites</Typography>}
          color={'primary'}
          onDelete={() => dealsStore.toggleFavorites()}
        />
      ) : (
        dealsStore.filteredCategories.map((category) => {
          return (
            <Chip
              key={category.id}
              className={classes.chip}
              size={'medium'}
              label={<Typography>{category.text}</Typography>}
              color={'primary'}
              onDelete={() => {
                dealsStore.removeCategoryFilter(category.id);
                setCurrentCategoryFilters(
                  currentCategoryFilters.filter((f) => f.id !== category.id),
                );
              }}
            />
          );
        })
      )}
    </div>
  );
};

export default observer(DealChip);

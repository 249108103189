import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const AppDrawerStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
    },
    avatarListItem: {
      display: 'flex',
      justifyContent: 'center',
      height: 80,
    },
    avatar: {
      width: 80,
      height: 80,
    },
    socialMediaListItem: {
      display: 'flex',
      flexDirection: 'column',
    },
    socialMediaIconContainer: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
    },
    facebookIcon: {
      color: '#3b5999',
    },
    twitterIcon: {
      color: '#55acee',
    },
    instagramIcon: {
      color: '#e4405f',
    },
  }),
);

export default AppDrawerStyles;

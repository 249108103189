import React from 'react';
import AppFooterStyles from './AppFooterStyles';
import Copyright from '../copyright';
import { Divider, Link, Typography } from '@material-ui/core';
import { history } from '../../../index';

const AppFooter: React.FC = () => {
  const classes = AppFooterStyles();
  return (
    <footer className={classes.root}>
      <Copyright />
    </footer>
  );
};

export default AppFooter;

import React from 'react';
import {
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import LoyaltyOutlinedIcon from '@material-ui/icons/LoyaltyOutlined';
import { FormikProps } from 'formik';
import LoyaltyCodeFormModel from '../../../common/models/loyalty/code/LoyaltyCodeFormModel';

interface LoyaltyCodeFormProps {
  formikProps: FormikProps<LoyaltyCodeFormModel>;
  classes: Record<'icon' | 'formContainer' | 'paper' | 'form', string>;
}

const LoyaltyCodeForm: React.FC<LoyaltyCodeFormProps> = ({
  formikProps,
  classes,
}) => {
  return (
    <form onSubmit={formikProps.handleSubmit} className={classes.form}>
      <Grid container spacing={4}>
        <Grid container item xs={12} justify={'center'}>
          <LoyaltyOutlinedIcon className={classes.icon} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant={'h6'} align={'center'}>
            LOYALTY CODE
          </Typography>
        </Grid>
        <Grid container item xs={12} justify={'center'}>
          <Typography component={'p'} variant={'subtitle1'}>
            To redeem your loyalty code please enter it in the field provided
            below, and then click on the submit button.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Loyalty Code"
            type="text"
            fullWidth={true}
            name={'loyaltyCode'}
            variant={'standard'}
            error={
              !!(
                formikProps.errors.loyaltyCode &&
                formikProps.touched.loyaltyCode
              )
            }
            helperText={
              formikProps.errors.loyaltyCode && formikProps.touched.loyaltyCode
                ? formikProps.errors.loyaltyCode
                : ''
            }
            onChange={formikProps.handleChange}
            onBlur={formikProps.handleBlur}
            value={formikProps.values.loyaltyCode}
            InputProps={{
              endAdornment: (
                <IconButton aria-label="Code" disabled={true}>
                  <LoyaltyOutlinedIcon />
                </IconButton>
              ),
            }}
          />
        </Grid>
        <Grid container item xs={12}>
          <Button
            variant={'contained'}
            color={'secondary'}
            type={'submit'}
            fullWidth
          >
            SUBMIT
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default LoyaltyCodeForm;

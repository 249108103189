import React from 'react';
import {
  Avatar,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Divider,
  IconButton,
  Typography,
} from '@material-ui/core';
import DealStyles from './DealStyles';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteOutlinedIcon from '@material-ui/icons/FavoriteOutlined';
import { history } from '../../../index';

interface DealCardProps {
  dealId: number;
  isFavorite: boolean;
  expiry?: string;
  tileImage: string;
  storeIcon?: string;
  storeName: string;
  title: string;
  onFavoriteButtonClick: () => void;
}

const DealCard: React.FC<DealCardProps> = (props) => {
  const classes = DealStyles();
  return (
    <Card elevation={5} className={classes.card}>
      <CardActionArea onClick={() => history.push(`/deals/${props.dealId}`)}>
        <CardHeader
          avatar={
            <Avatar variant={'square'}>
              {props.storeIcon ? (
                <img
                  src={props.storeIcon}
                  alt={'Store'}
                  width={'100%'}
                  loading={'lazy'}
                />
              ) : (
                <Typography>{props.storeName.charAt(0)}</Typography>
              )}
            </Avatar>
          }
          title={<Typography color={'primary'}>{props.storeName}</Typography>}
          subheader={
            <Typography color={'textSecondary'} variant={'subtitle2'}>
              {props.expiry}
            </Typography>
          }
        />
        <Divider />
        <CardMedia
          width={'100%'}
          component={'img'}
          image={props.tileImage}
          title={props.title}
          loading={'lazy'}
        />
        <Divider />
        <CardContent>
          <Typography variant="h6" color="textSecondary" component="p">
            {props.title}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions disableSpacing>
        <IconButton
          aria-label="add to favorites"
          className={classes.icon}
          onClick={props.onFavoriteButtonClick}
        >
          {props.isFavorite ? (
            <FavoriteOutlinedIcon color={'error'} />
          ) : (
            <FavoriteBorderIcon />
          )}
        </IconButton>
      </CardActions>
    </Card>
  );
};

export default DealCard;

const LANDING = '/landing';
const SIGN_UP = '/signup';
const SIGN_IN = '/signin';
const FORGOT_PASSWORD = '/forgotpassword';
const PRIVACY = '/privacy';
const TERMS = '/terms';
const SUPPORT = '/support';
const LOYALTY_CODE = '/loyaltycode';
const PROFILE = '/profile';
const FREQUENTLY_ASKED_QUESTIONS = '/faqs';
const LOYALTY_ACTIVITY = '/activity';
const LOYALTY_ACTIVITY_SCAN_DETAIL = `${LOYALTY_ACTIVITY}/scan/:id`;
const PROMOTIONS = '/promotions';
const PROMOTION_DETAIL = `${PROMOTIONS}/:id`;
const REWARDS = '/rewards';
const REWARD_DETAIL = `${REWARDS}/:id`;

export {
  LANDING,
  SIGN_UP,
  SIGN_IN,
  FORGOT_PASSWORD,
  PRIVACY,
  TERMS,
  SUPPORT,
  LOYALTY_CODE,
  PROFILE,
  FREQUENTLY_ASKED_QUESTIONS,
  LOYALTY_ACTIVITY,
  LOYALTY_ACTIVITY_SCAN_DETAIL,
  PROMOTIONS,
  PROMOTION_DETAIL,
  REWARDS,
  REWARD_DETAIL,
};

import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

const ApplicationTheme = responsiveFontSizes(
  createMuiTheme({
    palette: {
      primary: {
        main: '#d1a567',
        contrastText: '#FFFFFF',
      },
      secondary: {
        main: '#54b484',
        contrastText: '#FFFFFF',
      },
    },
  }),
);

export default ApplicationTheme;

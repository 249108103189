import React from 'react';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { FormikProps } from 'formik';
import RewardModel from '../../../common/models/loyalty/rewards/RewardModel';

interface RewardDetailFormProps {
  formikProps: FormikProps<{ quantity: number }>;
  rewards: RewardModel[];
  selectedReward: RewardModel;
  setSelectedReward: React.Dispatch<React.SetStateAction<RewardModel>>;
  classes: Record<'redeemButton', string>;
  pointsRemaining: number;
}

const RewardDetailForm: React.FC<RewardDetailFormProps> = ({
  formikProps,
  rewards,
  selectedReward,
  setSelectedReward,
  classes,
  pointsRemaining,
}) => {
  return (
    <form onSubmit={formikProps.handleSubmit}>
      <Grid container spacing={3}>
        {rewards.length > 1 ? (
          <Grid item xs={12}>
            <FormControl fullWidth={true}>
              <InputLabel>
                {rewards[0].Definitions.length > 1
                  ? 'Reward Length and Type'
                  : 'Number of Ride Points'}
              </InputLabel>
              <Select
                value={selectedReward.RewardID}
                onChange={(e) => {
                  const reward = rewards.find(
                    (f) => f.RewardID === e.target.value,
                  );
                  if (reward) {
                    setSelectedReward(reward);
                  }
                }}
                name={'reward'}
                id={'reward'}
                labelId={'reward'}
              >
                {rewards.map((group) => {
                  return (
                    <MenuItem key={group.RewardID} value={group.RewardID}>
                      {group.Definitions.map((f) => f.Title).join(', ')}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <TextField
            label="Quantity"
            type="number"
            error={
              !!(formikProps.errors.quantity && formikProps.touched.quantity)
            }
            helperText={
              formikProps.errors.quantity && formikProps.touched.quantity
                ? formikProps.errors.quantity
                : ''
            }
            fullWidth={true}
            name={'quantity'}
            value={formikProps.values.quantity}
            onChange={formikProps.handleChange}
            onBlur={formikProps.handleBlur}
            variant={'outlined'}
          />
        </Grid>
        <Grid container item xs={12} justify={'center'}>
          <Button
            variant={'contained'}
            className={classes.redeemButton}
            type={'submit'}
            disabled={
              pointsRemaining < selectedReward.CostInPoints ||
              selectedReward.CostInPoints * formikProps.values.quantity >
                pointsRemaining
            }
          >
            REDEEM
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default RewardDetailForm;

import React, { Dispatch, SetStateAction } from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import { IconButton } from '@material-ui/core';

interface AppHamburgerButtonProps {
  setDrawerOpen: Dispatch<SetStateAction<boolean>>;
  classes: Record<'menuButton', string>;
}

const AppHamburgerButton: React.FC<AppHamburgerButtonProps> = ({
  setDrawerOpen,
  classes,
}) => {
  return (
    <IconButton
      edge="start"
      className={classes.menuButton}
      color="inherit"
      aria-label="open drawer"
      onClick={() => setDrawerOpen(true)}
    >
      <MenuIcon />
    </IconButton>
  );
};

export default AppHamburgerButton;

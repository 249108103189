import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const AppStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
    },
  }),
);

export default AppStyles;

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const DealStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: '80px',
      marginBottom: '80px',
      flexGrow: 1,
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: 390,
    },
    cardHeader: {
      borderBottom: '1px solid black',
    },
    cardContent: {
      borderTop: '1px solid black',
    },
    button: {
      borderRadius: 8,
    },
    media: {
      height: 100,
      paddingTop: '56.25%', // 16:9
    },
    icon: {
      marginLeft: 'auto',
    },
    textMargins: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    redeemButton: {
      borderRadius: 8,
      minWidth: 250,
      minHeight: 65,
    },
    dealChipContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      overflowX: 'auto',
      width: '100%',
      [theme.breakpoints.up('lg')]: {
        height: 'auto',
        padding: theme.spacing(1),
      },
    },
    chip: {
      margin: theme.spacing(0.5),
    },
    appBar: {
      position: 'relative',
    },
    toolBar: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    emptyDealsContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: 'inherit',
    },
    emptyDealsIcon: {
      [theme.breakpoints.up('md')]: {
        width: 400,
        height: 400,
      },
      [theme.breakpoints.down('sm')]: {
        width: 250,
        height: 250,
      },
    },
  }),
);

export default DealStyles;

import React, { Fragment, useEffect } from 'react';
import PromotionStyles from './PromotionStyles';
import Stores from '../../../domain/stores/Stores';
import { observer } from 'mobx-react-lite';
import BottomAppBar from '../bottom-appbar';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Container, Grid } from '@material-ui/core';
import TopAppbar from '../top-appbar';
import PromotionBanner from './PromotionBanner';
import { history } from '../../../index';

const Promotion: React.FC = (props) => {
  const classes = PromotionStyles();
  const { promotionStore } = React.useContext(Stores);
  useEffect(() => {
    (async () => await promotionStore.getPromotions())();
  }, [promotionStore]);

  return (
    <Fragment>
      <TopAppbar showUserIcon={true} showPromoCodeIcon={true} />
      <Container maxWidth={'lg'} className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PromotionBanner />
          </Grid>
          {promotionStore.data?.promotions?.map((promotion) => {
            return (
              <Grid
                item
                xs={12}
                sm={4}
                md={4}
                zeroMinWidth={true}
                key={promotion.id}
              >
                <img
                  src={promotion.tileImage}
                  alt={promotion.title}
                  className={classes.promotionImage}
                  onClick={() => history.push(`/promotions/${promotion.id}`)}
                  loading={'lazy'}
                />
              </Grid>
            );
          })}
        </Grid>
      </Container>
      <BottomAppBar />
    </Fragment>
  );
};

export default observer(Promotion);

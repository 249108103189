import { action, observable } from 'mobx';
import BaseStore from '../BaseStore';
import { fileUpload, get } from '../../../common/network/agent';
import GlobalStore from '../GlobalStore';
import { DialogType } from '../../../common/enums/DialogType';
import SkiDataRoutes from '../../../common/config/routes/skidata-routes';
import WemRoutes from '../../../common/config/routes/wem-routes';
import ContactSupportModel from '../../../common/models/loyalty/support/ContactSupportModel';
import FAQModel from '../../../common/models/loyalty/support/FAQModel';

export default class SupportStore extends BaseStore {
  @observable frequentlyAskedQuestions: FAQModel[] = [];

  constructor(private globalStore: GlobalStore) {
    super();
  }

  @action getFrequentlyAskedQuestions = async () => {
    try {
      this.isLoading = true;
      const request = await get(SkiDataRoutes.simpleContent);

      if (request.ok) {
        const response = (await request.json()) as FAQModel[];
        this.frequentlyAskedQuestions = response.filter(
          (f) => f.SimpleContentCategoryID === 164,
        );
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  };

  @action contactSupport = async (
    data: ContactSupportModel,
  ): Promise<boolean> => {
    try {
      this.isLoading = true;
      const formData = new FormData();
      formData.append('IssueCategorization', data.IssueCategorization);
      formData.append('Description', data.Description);
      formData.append('LoyaltyId', data.LoyaltyId || '');
      for (let x = 0; x < data.Attachments?.length; x++) {
        formData.append('Attachments[]', data.Attachments[x]);
      }
      formData.append('Email', data.Email);
      formData.append('Name', data.Name);
      const response = await fileUpload(WemRoutes.contactSupport, formData);
      if (response.ok) {
        this.globalStore.showDialog(
          DialogType.Success,
          'Contact Support',
          `Your message has been sent successfully, and support will get in contact with you shortly`,
        );
        return true;
      } else {
        this.globalStore.showDialog(
          DialogType.Error,
          "It's not you. It's us.",
          `It looks like an error may have occurred. Please contact support@mywem.ca for any questions or concerns.`,
        );
        return false;
      }
    } catch (err) {
      if (!navigator.onLine) {
        this.globalStore.showDialog(DialogType.Error, 'Contact Support', err);
      } else {
        this.globalStore.showDialog(
          DialogType.Error,
          "It's not you. It's us.",
          `It looks like an error may have occurred. Please contact support@mywem.ca for any questions or concerns.`,
        );
      }
      return false;
    } finally {
      this.isLoading = false;
    }
  };
}

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const RewardStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: '80px',
      marginBottom: '80px',
      flexGrow: 1,
    },
    detailRoot: {
      flexGrow: 1,
    },
    appBar: {
      marginBottom: '10px',
      backgroundColor: theme.palette.primary.main,
    },
    title: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    gridList: {
      width: '100%',
      height: '100%',
    },
    gridListTile: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
    icon: {
      position: 'absolute',
      right: 10,
      transform: 'translate(0, -50%)',
      color: theme.palette.secondary.main,
    },
    divider: {
      backgroundColor: theme.palette.primary.main,
    },
    redeemButton: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    cost: {
      color: theme.palette.primary.main,
    },
    available: {
      color: theme.palette.secondary.main,
    },
    soldOutItemBackground: {
      backgroundColor: theme.palette.primary.main,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      color: theme.palette.background.paper,
    },
    availableItemBackground: {
      background: 'rgba(0, 0, 0, 0.6)',
    },
    caption: {
      position: 'absolute',
      bottom: '0px',
      width: '100%',
      minHeight: 50,
    },
    gridListTileBar: {
      padding: theme.spacing(1),
      color: theme.palette.background.paper,
    },
  }),
);

export default RewardStyles;

import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { ReactComponent as AppleShareIcon } from '../../../common/resources/images/share-icon.svg';

const ShareIcon: React.FC = () => {
  return (
    <SvgIcon color={'inherit'} fontSize={'default'}>
      <AppleShareIcon />
    </SvgIcon>
  );
};

export default ShareIcon;

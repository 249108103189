import React from 'react';
import { Typography } from '@material-ui/core';
import CopyrightStyles from './CopyrightStyles';
import { VERSION } from '../../../common/constants/MyWEMConstants';

const Copyright: React.FC = () => {
  const classes = CopyrightStyles();
  return (
    <Typography variant="subtitle2" className={classes.textColor}>
      Copyright &#169; {new Date().getFullYear()} West Edmonton Mall &bull;
      Version {VERSION}
    </Typography>
  );
};

export default Copyright;

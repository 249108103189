export default class UserRegistrationModel {
  public firstName: string = '';
  public lastName: string = '';
  public email: string = '';
  public postalCode: string = '';
  public birthDate: Date | null = null;
  public gender: string = '';
  public password: string = '';
  public offersemail: boolean = false;
}

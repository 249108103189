import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const PromotionStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(7),
    },
    carousel: {
      marginBottom: theme.spacing(1),
    },
    promotionImage: {
      width: '100%',
      borderRadius: '8px',
      '&:hover': {
        cursor: 'pointer',
        transition: '.2s',
        transform: 'translate(0, -8px)',
      },
    },
    bannerImage: {
      width: '100%',
    },
    bannerContainer: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
  }),
);

export default PromotionStyles;

import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { withRouter, RouteComponentProps, Redirect } from 'react-router-dom';
import Stores from '../../../domain/stores/Stores';
import { Grid, Typography } from '@material-ui/core';
import RewardStyles from './RewardStyles';
import { Formik } from 'formik';
import RewardDetailFormSchema from './RewardDetailFormSchema';
import DetailPage from '../detail-page';
import { history } from '../../../index';
import RewardDetailForm from './RewardDetailForm';
import HeroImageModel from '../../../common/models/app/HeroImageModel';
import RewardModel from '../../../common/models/loyalty/rewards/RewardModel';

const RewardDetail: React.FC<RouteComponentProps<{ id: string }>> = (props) => {
  const { rewardStore, userStore } = useContext(Stores);
  const rewards = rewardStore.getRewardByLoyaltyProductId(
    parseInt(props.match.params.id),
  );
  const [selectedReward, setSelectedReward] = useState(
    rewards ? (rewards[0] as RewardModel) : ({} as RewardModel),
  );
  const classes = RewardStyles();

  return rewards ? (
    <DetailPage
      title={selectedReward.Title || 'Reward Detail'}
      backButtonAction={() => history.push('/rewards')}
      HeroImage={
        new HeroImageModel(
          rewards.length > 1
            ? selectedReward.PopupImage
            : selectedReward.FeaturedImage,
          selectedReward.Title,
        )
      }
      CaptionText={selectedReward.Title}
      BodyContent={
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography component={'div'} variant={'body1'} gutterBottom={true}>
              <span
                //@ts-ignore
                dangerouslySetInnerHTML={{ __html: selectedReward.Description }}
              />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'body1'}>
              Cost:{' '}
              <span className={classes.cost}>
                {selectedReward.CostInPoints} pts
              </span>{' '}
              | Available:{' '}
              <span className={classes.available}>
                {userStore.userModel?.Data.CurrentPoints?.PointsRemaining || 0}
              </span>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Formik
              initialValues={{ quantity: 0 }}
              validationSchema={RewardDetailFormSchema}
              onSubmit={async (values) => {
                const success = await rewardStore.redeemReward(
                  selectedReward.RewardID,
                  values.quantity,
                );
                if (success) {
                  await userStore.getUser();
                }
              }}
            >
              {(formikProps) => (
                <RewardDetailForm
                  formikProps={formikProps}
                  rewards={rewards}
                  selectedReward={selectedReward}
                  setSelectedReward={setSelectedReward}
                  classes={classes}
                  pointsRemaining={
                    userStore.userModel?.Data.CurrentPoints?.PointsRemaining ||
                    0
                  }
                />
              )}
            </Formik>
          </Grid>
        </Grid>
      }
    />
  ) : (
    <Redirect to={'/notfound/rewards'} />
  );
};

export default withRouter(observer(RewardDetail));

import React, { ReactNode, useContext } from 'react';
import { Button, IconButton, useMediaQuery, useTheme } from '@material-ui/core';
import { history } from '../../../index';
import LoyaltyOutlinedIcon from '@material-ui/icons/LoyaltyOutlined';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { DialogType } from '../../../common/enums/DialogType';
import WifiOffRoundedIcon from '@material-ui/icons/WifiOffRounded';
import AppToolBarStyles from './AppToolBarStyles';
import Stores from '../../../domain/stores/Stores';
import { observer } from 'mobx-react-lite';

interface AppToolBarActionRowProps {
  showPromoCodeIcon?: boolean;
  showUserIcon?: boolean;
  actionButton?: ReactNode;
}

const AppToolBarActionRow: React.FC<AppToolBarActionRowProps> = ({
  showPromoCodeIcon,
  showUserIcon,
  actionButton,
}) => {
  const { globalStore } = useContext(Stores);
  const classes = AppToolBarStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <div className={classes.actionBarContainer}>
      {showPromoCodeIcon ? (
        matches ? (
          <Button
            startIcon={<LoyaltyOutlinedIcon />}
            aria-label="loyalty promo code"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            color="inherit"
            onClick={() => history.push('/loyaltycode')}
          >
            Loyalty Code
          </Button>
        ) : (
          <IconButton
            aria-label="loyalty promo code"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            color="inherit"
            onClick={() => history.push('/loyaltycode')}
          >
            <LoyaltyOutlinedIcon />
          </IconButton>
        )
      ) : null}
      {showUserIcon ? (
        matches ? (
          <Button
            startIcon={<AccountCircle />}
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={() => history.push('/profile')}
            color="inherit"
          >
            PROFILE
          </Button>
        ) : (
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={() => history.push('/profile')}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
        )
      ) : null}
      {actionButton ? actionButton : null}
      {globalStore.isOnline ? null : (
        <IconButton
          aria-label="offline"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={() => {
            globalStore.showDialog(
              DialogType.Error,
              'Offline',
              'You are currently browsing the application in offline mode.',
            );
          }}
        >
          <WifiOffRoundedIcon />
        </IconButton>
      )}
    </div>
  );
};

export default observer(AppToolBarActionRow);

import { action, observable } from 'mobx';
import { DialogType } from '../../common/enums/DialogType';
import NotificationModel from '../../common/models/app/NotificationModel';
import { ReactNode } from 'react';
import { isInStandaloneMode, isIos } from '../../common/utils/DeviceHelper';
import { history } from '../../index';
import { GetRouteValue } from '../../common/utils/RouteHelper';

export default class GlobalStore {
  @observable notification: NotificationModel = {
    Open: false,
  } as NotificationModel;
  @observable isOnline: boolean = true;
  @observable routeValue: number | null = null;
  installPromptStorageName: string = 'myWemApplicationInstallPrompt';

  constructor() {
    // Triggered when the document is loaded.
    window.addEventListener('load', () => {
      // Checks if the user is online
      window.addEventListener('online', () => {
        this.isOnline = true;
      });
      // Checks if the user is offline, will trigger an offline icon to the observable
      // functions listening to it.
      window.addEventListener('offline', () => {
        this.isOnline = false;
      });
      // Updates the route value to reflect on the menu component.
      history.listen((location) => {
        this.setRouteValue(GetRouteValue(location.pathname));
      });
    });
  }

  /**
   * Global notification/information dialog toggle, called from observable classes
   * */
  @action showDialog = (
    type: DialogType,
    title: string,
    message: string | ReactNode,
  ) => {
    this.notification.Open = true;
    this.notification.Title = title;
    this.notification.Message = message;
    this.notification.Type = type;
    this.notification.OnClose = () => {
      this.notification = {
        Open: false,
      } as NotificationModel;
    };
  };

  /**
   * Informs the user of any pending installation.
   * */
  @action notifyOfNewApplicationUpdates = (
    registration: ServiceWorkerRegistration,
  ) => {
    this.showDialog(
      DialogType.Info,
      'MyWEM Application Updates',
      'A new version of MyWem is available. Please close the application and reopen it.',
    );
  };

  /**
   * Sets the currently selected route index value.
   * @param value currently selected route index number
   * */
  @action setRouteValue = (value: number | null) => (this.routeValue = value);

  /**
   * Utilized on Apple devices to prompt the installation instruction. At the time
   * of writing Apple does not auto prompt the user to install like android does.
   * */
  @action showShowInstallPrompt = () =>
    isIos() &&
    !isInStandaloneMode() &&
    localStorage.getItem(this.installPromptStorageName) === null;
}

import React, { Fragment, useContext, useEffect } from 'react';
import BottomAppBar from '../bottom-appbar';
import { Container, Tab, Tabs, Typography, useTheme } from '@material-ui/core';
import LoyaltyActivityStyles from './LoyaltyActivityStyles';
import History from './LoyaltyActivityHistory';
import Scans from './LoyaltyActivityScans';
import SwipeableViews from 'react-swipeable-views';
import TabPanel from './LoyaltyActivityTabPanel';
import { observer } from 'mobx-react-lite';
import Stores from '../../../domain/stores/Stores';
import TopAppbar from '../top-appbar';

const LoyaltyActivity: React.FC = () => {
  const classes = LoyaltyActivityStyles();
  const theme = useTheme();
  const { loyaltyActivityStore, pointStore, receiptStore } = useContext(Stores);

  useEffect(() => {
    (async () => {
      await pointStore.getPointRecord();
      await receiptStore.getScanRecords();
    })();
  }, [pointStore, receiptStore]);

  return (
    <Fragment>
      <TopAppbar showUserIcon={true} showPromoCodeIcon={true} />
      <Container maxWidth={'md'} className={classes.root}>
        <Tabs
          orientation={'horizontal'}
          value={loyaltyActivityStore.selectedTabIndex}
          onChange={loyaltyActivityStore.handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label={<Typography>POINTS</Typography>} id={'points'} />
          <Tab label={<Typography>RECEIPTS</Typography>} id={'receipts'} />
        </Tabs>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={loyaltyActivityStore.selectedTabIndex}
          onChangeIndex={loyaltyActivityStore.handleChangeIndex}
        >
          <TabPanel
            index={0}
            value={loyaltyActivityStore.selectedTabIndex}
            dir={theme.direction}
          >
            <History pointRecords={pointStore.pointRecord} />
          </TabPanel>
          <TabPanel
            index={1}
            value={loyaltyActivityStore.selectedTabIndex}
            dir={theme.direction}
          >
            <Scans scanRecords={receiptStore.scanRecord} />
          </TabPanel>
        </SwipeableViews>
      </Container>
      <BottomAppBar />
    </Fragment>
  );
};

export default observer(LoyaltyActivity);

import React, { useContext, Fragment, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Stores from '../../../domain/stores/Stores';
import ScanLoyaltyStyles from './ScanLoyaltyStyles';
import BottomAppBar from '../bottom-appbar';
import { Container, Grid, Typography } from '@material-ui/core';
import ScanLoyaltyDialog from './ScanLoyaltyDialog';
import LoyaltyCard from '../loyalty-card';
import TopAppbar from '../top-appbar';

const ScanLoyalty: React.FC = () => {
  const { userStore } = useContext(Stores);
  const localStorageName: string = 'myWemLoyaltyBarcodeDialog';
  const classes = ScanLoyaltyStyles();
  const [showDialog, setShowDialog] = useState(
    localStorage.getItem(
      localStorageName + userStore.userModel?.Data.UserID,
    ) === null &&
      localStorage.getItem(
        localStorageName + userStore.userModel?.Data.UserID,
      ) !== 'true',
  );

  const toggleLoyaltyScanDialogVisibility = (visible: boolean) => {
    if (visible) {
      localStorage.setItem(
        localStorageName + userStore.userModel?.Data.UserID,
        'true',
      );
    } else {
      localStorage.removeItem(
        localStorageName + userStore.userModel?.Data.UserID,
      );
    }
  };

  return (
    <Fragment>
      <TopAppbar showUserIcon={true} showPromoCodeIcon={true} />
      <Container maxWidth={'md'} className={classes.root}>
        <Grid container spacing={4}>
          <Grid container item xs={12} justify={'center'}>
            <LoyaltyCard
              value={userStore.getUserLoyaltyAccountId()}
              showLoyaltyUsageDialog={setShowDialog}
            />
          </Grid>
          <Grid container item xs={12} justify={'center'}>
            <Typography component={'h6'} variant={'h5'}>
              Available Points:{' '}
              <span className={classes.available}>
                {userStore.userModel?.Data.CurrentPoints?.PointsRemaining || 0}
              </span>
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <BottomAppBar />
      <ScanLoyaltyDialog
        open={showDialog}
        initialCheckboxValue={
          localStorage.getItem(
            localStorageName + userStore.userModel?.Data.UserID,
          ) !== null
        }
        onCancel={() => setShowDialog(false)}
        onOk={(hideScanDialog: boolean) => {
          toggleLoyaltyScanDialogVisibility(hideScanDialog);
          setShowDialog(false);
        }}
      />
    </Fragment>
  );
};

export default observer(ScanLoyalty);

import { action, observable } from 'mobx';
import BaseStore from '../BaseStore';
import { get, post } from '../../../common/network/agent';
import GlobalStore from '../GlobalStore';
import { DialogType } from '../../../common/enums/DialogType';
import SkiDataRoutes from '../../../common/config/routes/skidata-routes';
import _ from 'lodash';
import RewardModel from '../../../common/models/loyalty/rewards/RewardModel';
import RewardCategoryResponseModel from '../../../common/models/loyalty/rewards/RewardCategoryResponseModel';
import UserStore from '../UserStore';

export default class RewardStore extends BaseStore {
  @observable rewards: RewardModel[] = [];
  @observable rewardsCategories: RewardCategoryResponseModel | null = null;
  @observable categoryId: number = -1;

  constructor(private globalStore: GlobalStore, private userStore: UserStore) {
    super();
  }

  @action getRewards = async () => {
    try {
      this.isLoading = true;

      // Get categories first
      await this.getRewardCategories();

      if (this.rewardsCategories?.Data) {
        const request = await get(SkiDataRoutes.rewards);

        if (request.ok) {
          this.rewards = [];
          this.rewards = (await request.json()) as RewardModel[];
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  };

  @action redeemReward = async (
    rewardId: number,
    quantity: number,
  ): Promise<boolean> => {
    let success = true;
    try {
      this.isLoading = true;
      const response = await post(
        `${SkiDataRoutes.rewards}/${rewardId}`,
        JSON.stringify({ quantity: quantity }),
      );

      const data = await response.json();
      if (response.ok) {
        await this.userStore.getUser();
        this.globalStore.showDialog(
          DialogType.Success,
          'Redeem Reward',
          `Congratulations! Your reward is on the way to your inbox!`,
        );
      } else {
        success = false;
        this.globalStore.showDialog(
          DialogType.Error,
          'Redeem Reward',
          data.Message,
        );
      }
    } catch (err) {
      success = false;
      this.globalStore.showDialog(DialogType.Error, 'Redeem Reward', err);
    } finally {
      this.isLoading = false;
    }
    return success;
  };

  getRewardsByCategory = (): RewardModel[][] => {
    return _(this.rewards)
      .filter(
        (f) => f.RewardCategoryID === this.categoryId || this.categoryId === -1,
      )
      .groupBy((item) => item.LoyaltyProductID)
      .sortBy((group) => group[0].Ordinal)
      .value();
  };

  getRewardByLoyaltyProductId = (
    loyaltyProductId: number,
  ): RewardModel[] | undefined => {
    const rewardsByProductId = this.rewards.filter(
      (f) => f.LoyaltyProductID === loyaltyProductId,
    );
    if (rewardsByProductId && rewardsByProductId.length > 0) {
      return rewardsByProductId;
    } else {
      return undefined;
    }
  };

  @action getRewardCategories = async () => {
    const response = await get(SkiDataRoutes.rewardCategory);
    this.rewardsCategories = (await response.json()) as RewardCategoryResponseModel;
  };
}

import React, { useContext, Fragment } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Stores from '../../../domain/stores/Stores';
import LoyaltyActivityStyles from './LoyaltyActivityStyles';
import { Container, Grid, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import TopAppbar from '../top-appbar';

const ScanDetail: React.FC<RouteComponentProps<{ id: string }>> = (props) => {
  const { receiptStore } = useContext(Stores);
  const classes = LoyaltyActivityStyles();
  const scan = receiptStore.getScanReceiptById(parseInt(props.match.params.id));
  const image = scan?.Images[0];

  return (
    <Fragment>
      <TopAppbar backButtonActionOrUrl={'/activity'} />
      <Container maxWidth={'md'} className={classes.root}>
        <Grid container spacing={2}>
          <Grid container item xs={12} justify={'center'}>
            <img
              src={image?.OriginalURL}
              alt={scan?.StatusDescription}
              height={300}
            />
          </Grid>
          <Grid container item xs={12} spacing={1}>
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={6} container justify={'flex-end'}>
                <Typography component="span" color={'textPrimary'}>
                  STATUS :
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography component="span" color={'textPrimary'}>
                  {scan?.StatusDescription}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={6} container justify={'flex-end'}>
                <Typography component="span" color={'textPrimary'}>
                  STORE :
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography component="span" color={'textPrimary'}>
                  {scan?.StoreName}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={6} container justify={'flex-end'}>
                <Typography component="span" color={'textPrimary'}>
                  DATE :
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography component="span" color={'textPrimary'}>
                  {scan?.DateCreated
                    ? new Date(scan.DateCreated).toLocaleDateString()
                    : null}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={6} container justify={'flex-end'}>
                <Typography component="span" color={'textPrimary'}>
                  AMOUNT :
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography component="span" color={'textPrimary'}>
                  {scan?.Amount}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={6} container justify={'flex-end'}>
                <Typography component="strong" color={'textPrimary'}>
                  TRANSACTION ID :
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography component="span" color={'textPrimary'}>
                  {scan?.ExternalID}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};

export default withRouter(observer(ScanDetail));

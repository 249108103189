import React, { useContext, useState } from 'react';
import { Snackbar, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Stores from '../../../domain/stores/Stores';
import { observer } from 'mobx-react-lite';
import ShareIcon from '../share-icon';

const InstallAppSnackbar: React.FC = () => {
  const { globalStore } = useContext(Stores);
  const [shouldShowInstallPrompt, setShouldShowInstallPrompt] = useState(
    globalStore.showShowInstallPrompt(),
  );
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={shouldShowInstallPrompt}
      onClose={() => {
        localStorage.setItem(globalStore.installPromptStorageName, 'true');
        setShouldShowInstallPrompt(false);
      }}
      message={
        <Typography
          style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          Add this web app to your home screen! Tap <ShareIcon /> and then Add
          to Home Screen!
        </Typography>
      }
      action={
        <React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => {
              localStorage.setItem(
                globalStore.installPromptStorageName,
                'true',
              );
              setShouldShowInstallPrompt(false);
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      }
    />
  );
};

export default observer(InstallAppSnackbar);

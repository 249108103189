const SkiDataRoutes = {
  authentication: {
    loginType: '/DesktopModules/v1/API/Authentication/GetLoginType',
    validate: '/DesktopModules/v1/API/Authentication/Validate',
    resetPassword: '/DesktopModules/v1/API/Authentication/ResetPassword',
    logout: '/DesktopModules/v1/API/Authentication/Logout',
  },
  user: {
    getUser: '/DesktopModules/v2/API/User/GetUser',
  },
  userProfile: {
    updateProfile: '/DesktopModules/v2/API/UserProfile/UpdateProfile',
  },
  profileImage: {
    upload: '/DesktopModules/v1/API/ProfileImage/Upload',
  },
  userRegistration: {
    registration: '/DesktopModules/v1/API/Registration',
  },
  loyaltyCodes: '/DesktopModules/v1/API/LoyaltyCodes',
  sso: {
    facebook: '/facebook/46/v1/SSO',
  },
  pointActivities: '/DesktopModules/v1/API/PointActivities',
  points: '/DesktopModules/v2/API/Points',
  pointActivityType: '/DesktopModules/v1/API/PointActivityType/GetTypes',
  receipt: '/DesktopModules/v1/API/ZipLoop/Scan',
  rewardCategory: '/DesktopModules/v2/API/RewardCategory',
  rewards: '/DesktopModules/v1/API/Rewards',
  simpleContent: '/DesktopModules/v1/API/SimpleContent/GetArticlesByCategory',
};

export default SkiDataRoutes;

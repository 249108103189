import React, { Fragment } from 'react';
import { history } from '../../../index';
import { Button, Container, Grid, Typography } from '@material-ui/core';
import MoodBadOutlinedIcon from '@material-ui/icons/MoodBadOutlined';
import NotFoundStyles from './NotFoundStyles';
import { RouteComponentProps } from 'react-router-dom';
import TopAppbar from '../top-appbar';

const NotFound: React.FC<RouteComponentProps<{ path?: string }>> = (props) => {
  const classes = NotFoundStyles();
  return (
    <Fragment>
      <TopAppbar
        backButtonActionOrUrl={() => {
          const returnPath = props.match.params.path;
          if (returnPath) {
            history.push(`/${returnPath}`);
          } else {
            history.goBack();
          }
        }}
      />
      <Container
        component={'main'}
        maxWidth={'sm'}
        className={classes.container}
      >
        <div className={classes.paper}>
          <Grid container spacing={4}>
            <Grid container item xs={12} justify={'center'}>
              <MoodBadOutlinedIcon className={classes.icon} />
            </Grid>
            <Grid container item xs={12} justify={'center'}>
              <Typography component={'h1'} variant={'h2'}>
                Page Not Found
              </Typography>
            </Grid>
            <Grid container item xs={12} justify={'center'}>
              <Button
                color={'secondary'}
                type={'submit'}
                onClick={() => history.goBack()}
              >
                It is okay, I forgive you. Now take me back to where I was.
              </Button>
            </Grid>
          </Grid>
        </div>
      </Container>
    </Fragment>
  );
};

export default NotFound;

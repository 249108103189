import React from 'react';
import ReactDOM from 'react-dom';
import App from './ui/App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { Router } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { createBrowserHistory } from 'history';
import ApplicationTheme from './common/config/theme/application-theme';
import 'fontsource-roboto';
import ReactGA, { ga } from 'react-ga';
import { Stores } from './domain/stores/Stores';

// Allows access to the current history api of the application, and exposes functions to call.
export const history = createBrowserHistory();

// Used to initialize GA reporting library with the tracking id
ReactGA.initialize(`${process.env.REACT_APP_GOOGLE_TRACKING_ID}`);

// Used by the GA library to report on page views
history.listen((location) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

ReactDOM.render(
  <ThemeProvider theme={ApplicationTheme}>
    <Router history={history}>
      <App />
    </Router>
  </ThemeProvider>,
  document.getElementById('root') || document.createElement('div'),
);

// Notification sent to the user to inform of pending updates for the application
serviceWorkerRegistration.register({
  onUpdate: (registration: ServiceWorkerRegistration) =>
    Stores.globalStore.notifyOfNewApplicationUpdates(registration),
});

// Application performance reporting
reportWebVitals(function sendToAnalytics({ id, name, value }) {
  ga('send', 'event', {
    eventCategory: 'MyWEM Web Vitals',
    eventAction: name,
    eventValue: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
    eventLabel: id, // id unique to current page load
    nonInteraction: true, // avoids affecting bounce rate
  });
});

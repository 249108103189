import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const WrapperStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: 'inherit',
      height: 'inherit',
    },
  }),
);

export default WrapperStyles;

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const AppFooterStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'absolute',
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      bottom: 20,
    },
    privacyAndTermsContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    link: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
  }),
);

export default AppFooterStyles;

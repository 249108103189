import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const BottomAppbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'fixed',
      bottom: 0,
      width: 'inherit',
    },
  }),
);

export default BottomAppbarStyles;

import React, { Fragment, useContext, useEffect } from 'react';
import MyWemLogo from '../../../common/resources/images/mywem.svg';
import { Button, Container, Grid, Typography } from '@material-ui/core';
import LandingStyles from './LandingStyles';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { history } from '../../../index';
import Stores from '../../../domain/stores/Stores';
import FacebookButton from '../facebook-button';
import InstallAppSnackbar from '../install-app-snackbar';
import AppFooter from '../app-footer';

const Landing: React.FC<RouteComponentProps> = (props) => {
  const classes = LandingStyles();
  const { userStore, authStore } = useContext(Stores);

  useEffect(() => {
    userStore.resetStore();
    authStore.resetStore();
  }, [userStore, authStore]);

  return (
    <Fragment>
      <Container maxWidth={'md'} className={classes.root}>
        <Grid container spacing={2}>
          <Grid
            item
            container
            justify={'center'}
            xs={12}
            alignItems={'flex-start'}
          >
            <img
              src={MyWemLogo}
              alt={'West Edmonton Mall'}
              className={classes.image}
            />
          </Grid>
          <Grid item container justify={'center'} xs={12}>
            <Typography variant={'h3'}>
              <span className={classes.titleColor}>My</span>
              <b>WEM</b>
            </Typography>
          </Grid>
          <Grid item container justify={'center'} xs={12}>
            <Typography variant="subtitle2" className={classes.subtitleColor}>
              BY WEST EDMONTON MALL
            </Typography>
          </Grid>
          <Grid item container justify={'center'} xs={12}>
            <Typography variant="subtitle2" className={classes.subtitleColor}>
              MyWEM Rewards Program is no longer available.
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <AppFooter />
      <InstallAppSnackbar />
    </Fragment>
  );
};

export default withRouter(Landing);

import {
  FREQUENTLY_ASKED_QUESTIONS,
  LOYALTY_ACTIVITY,
  PRIVACY,
  PROMOTIONS,
  REWARDS,
  SUPPORT,
  TERMS,
} from '../constants/AppRoutes';

/**
 * Menu helper function, used to identify the currently selected menu
 * @param path given a path returns the associated index number
 * */
const GetRouteValue = (path: string): number | null => {
  switch (path) {
    case PROMOTIONS:
      return 0;
    case LOYALTY_ACTIVITY:
      return 3;
    case REWARDS:
      return 4;
    case SUPPORT:
      return 5;
    case FREQUENTLY_ASKED_QUESTIONS:
      return 7;
    case PRIVACY:
      return 8;
    case TERMS:
      return 9;
    default:
      return null;
  }
};

export { GetRouteValue };

import React, { ChangeEvent, createRef } from 'react';
import { FormikProps } from 'formik';
import {
  Avatar,
  Badge,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import { history } from '../../../index';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import GenderList from '../../../common/config/data/GenderList';
import ProfileUpdateModel from '../../../common/models/user/profile/ProfileUpdateModel';

interface IProfileFormProps {
  formikProps: FormikProps<ProfileUpdateModel>;
  classes: Record<
    | 'root'
    | 'avatar'
    | 'userImage'
    | 'infoPaper'
    | 'saveProfileButton'
    | 'editIconButton'
    | 'inputFile'
    | 'selectEmpty',
    string
  >;
  userPhoto: string | null | undefined;
  uploadPhoto: (e: ChangeEvent<HTMLInputElement>) => Promise<void>;
  resetPassword: (username: string) => Promise<void>;
}

const ProfileForm: React.FC<IProfileFormProps> = ({
  formikProps,
  classes,
  userPhoto,
  uploadPhoto,
  resetPassword,
}) => {
  const uploadProfilePicRef = createRef<HTMLInputElement>();
  return (
    <form onSubmit={formikProps.handleSubmit}>
      <Grid container spacing={4}>
        <Grid container item xs={12} justify={'center'}>
          <input
            ref={uploadProfilePicRef}
            accept={'.jpg, .gif, .png, .jpeg'}
            className={classes.inputFile}
            id="upload-file"
            type="file"
            onChange={uploadPhoto}
          />
          <Badge
            badgeContent={
              <label htmlFor={'upload-file'}>
                <IconButton
                  color="secondary"
                  aria-label="upload picture"
                  component="span"
                >
                  <EditOutlinedIcon color={'secondary'} />
                </IconButton>
              </label>
            }
          >
            <Avatar
              classes={{
                root: classes.avatar,
              }}
              onClick={() => {
                uploadProfilePicRef.current?.click();
              }}
            >
              {userPhoto ? (
                <img src={userPhoto} alt={'Profile'} width={'100%'} />
              ) : (
                <AccountCircleOutlinedIcon
                  classes={{
                    root: classes.userImage,
                  }}
                />
              )}
            </Avatar>
          </Badge>
        </Grid>
        <Grid item xs={12}>
          <Typography variant={'h6'} align={'center'}>
            Hello, {formikProps.values.firstName}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="First Name (+25 Points)"
            type="text"
            variant={'standard'}
            fullWidth={true}
            name={'firstName'}
            onBlur={formikProps.handleBlur}
            onChange={formikProps.handleChange}
            value={formikProps.values.firstName}
            error={
              !!(formikProps.errors.firstName && formikProps.touched.firstName)
            }
            helperText={
              formikProps.errors.firstName && formikProps.touched.firstName
                ? formikProps.errors.firstName
                : ''
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Last Name (+25 Points)"
            type="text"
            variant={'standard'}
            fullWidth={true}
            name={'lastName'}
            onBlur={formikProps.handleBlur}
            onChange={formikProps.handleChange}
            value={formikProps.values.lastName}
            error={
              !!(formikProps.errors.lastName && formikProps.touched.lastName)
            }
            helperText={
              formikProps.errors.lastName && formikProps.touched.lastName
                ? formikProps.errors.lastName
                : ''
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Email Address (+25 Points)"
            type="email"
            variant={'standard'}
            fullWidth={true}
            name={'email'}
            disabled={true}
            value={formikProps.values.email}
          />
          <Link onClick={() => history.push('/support')}>
            Contact support to make email changes
          </Link>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Postal Code / Zip Code (+25 Points)"
            type="text"
            variant={'standard'}
            fullWidth={true}
            name={'postalCode'}
            onBlur={formikProps.handleBlur}
            onChange={formikProps.handleChange}
            value={formikProps.values.postalCode}
            error={
              !!(
                formikProps.errors.postalCode && formikProps.touched.postalCode
              )
            }
            helperText={
              formikProps.errors.postalCode && formikProps.touched.postalCode
                ? formikProps.errors.postalCode
                : ''
            }
          />
        </Grid>
        <Grid item xs={12}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableFuture
              margin="normal"
              id="birthDate"
              openTo="year"
              style={{ width: '100%' }}
              name={'birthDate'}
              onChange={(date: Date | null) =>
                formikProps.setFieldValue('birthDate', date, false)
              }
              format="MM/dd/yyyy"
              label="What's Your Birthday? (+25 Points)"
              views={['year', 'month', 'date']}
              value={formikProps.values.birthDate}
              helperText={
                formikProps.errors.birthDate && formikProps.touched.birthDate
                  ? formikProps.errors.birthDate
                  : ''
              }
              error={
                !!(
                  formikProps.errors.birthDate && formikProps.touched.birthDate
                )
              }
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12}>
          <FormControl
            fullWidth={true}
            error={!!(formikProps.errors.gender && formikProps.touched.gender)}
          >
            <InputLabel>Gender (+25 Points)</InputLabel>
            <Select
              labelId="gender"
              id="gender"
              name={'gender'}
              value={formikProps.values.gender}
              onBlur={formikProps.handleBlur}
              onChange={formikProps.handleChange}
            >
              {GenderList.map((gender) => {
                return (
                  <MenuItem key={gender.Key} value={gender.Key}>
                    {gender.Value}
                  </MenuItem>
                );
              })}
            </Select>
            {formikProps.errors.gender && formikProps.touched.gender ? (
              <FormHelperText>{formikProps.errors.gender}</FormHelperText>
            ) : null}
          </FormControl>
        </Grid>
        <Grid container item xs={12} justify={'center'}>
          <Button
            variant={'contained'}
            classes={{
              root: classes.saveProfileButton,
            }}
            type={'submit'}
            fullWidth={true}
          >
            Save
          </Button>
        </Grid>
        <Grid container item xs={12} justify={'center'}>
          <Button
            variant={'contained'}
            classes={{
              root: classes.saveProfileButton,
            }}
            fullWidth={true}
            onClick={async () => {
              if (formikProps.values.email) {
                await resetPassword(formikProps.values.email);
              }
            }}
          >
            Change Password
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default ProfileForm;

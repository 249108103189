import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { DialogType } from '../../../common/enums/DialogType';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import NotificationModel from '../../../common/models/app/NotificationModel';

const Notification: React.FC<NotificationModel> = (props) => {
  const theme = useTheme();
  const iconSize = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  })
    ? 'small'
    : 'default';
  const getDialogIcon = () => {
    switch (props.Type) {
      case DialogType.Success:
        return (
          <CheckCircleOutlineOutlinedIcon
            color={'secondary'}
            fontSize={iconSize}
          />
        );
      case DialogType.Error:
        return <ErrorOutlineOutlinedIcon color={'error'} fontSize={iconSize} />;
      case DialogType.Warning:
        return (
          <ReportProblemOutlinedIcon color={'primary'} fontSize={iconSize} />
        );
      default:
        return <InfoOutlinedIcon />;
    }
  };

  return (
    <Dialog open={props.Open} onClose={() => props.OnClose && props.OnClose()}>
      <DialogTitle>
        <Grid container direction={'row'} spacing={2}>
          <Grid item xs={1} container justify={'center'}>
            {getDialogIcon()}
          </Grid>
          <Grid item xs={10} container alignItems={'center'}>
            <Typography component={'span'} align={'center'}>
              {props.Title}
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <DialogContentText component={'span'}>
          <Typography component={'span'} variant={'body1'}>
            {props.Message}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => props.OnClose && props.OnClose()}
          color={'secondary'}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Notification;

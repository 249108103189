import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
const ScanLoyaltyStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
    },
    available: {
      color: theme.palette.secondary.main,
    },
  }),
);

export default ScanLoyaltyStyles;

import BaseStore from './BaseStore';
import { action, observable } from 'mobx';
import { fileUpload, get, post } from '../../common/network/agent';
import React, { ChangeEvent } from 'react';
import { DialogType } from '../../common/enums/DialogType';
import GlobalStore from './GlobalStore';
import SkiDataRoutes from '../../common/config/routes/skidata-routes';
import AuthStore from './AuthStore';
import { format } from 'date-fns';
import ProfilePropertyModel from '../../common/models/user/profile/ProfilePropertyModel';
import ProfileUpdateModel from '../../common/models/user/profile/ProfileUpdateModel';
import UserModel from '../../common/models/user/UserModel';
import UserRegistrationModel from '../../common/models/user/registration/UserRegistrationModel';
import RegistrationFormModel from '../../common/models/user/registration/RegistrationFormModel';
import ProfilePropertyFormModel from '../../common/models/user/registration/ProfilePropertyFormModel';

export default class UserStore extends BaseStore {
  @observable userModel: UserModel | null = null;
  @observable
  userRegistration: UserRegistrationModel = new UserRegistrationModel();

  constructor(public globalStore: GlobalStore, public authStore: AuthStore) {
    super();
  }

  @action getUser = async () => {
    try {
      this.isLoading = true;
      const response = await get(SkiDataRoutes.user.getUser);
      if (response.ok) {
        this.userModel = (await response.json()) as UserModel;
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  };

  @action updateRegistrationData = (
    e: React.FocusEvent<any> | React.ChangeEvent<any>,
  ) => {
    //@ts-ignore
    this.userRegistration[e.target.name] = e.target.value;
  };

  @action updateBirthDate = (date: Date | null) => {
    this.userRegistration.birthDate = date;
  };
  @action updateOffersEmail = (checked: boolean) => {
    this.userRegistration.offersemail = checked;
  };

  @action updateUserProfileData = async (model: ProfileUpdateModel) => {
    try {
      this.isLoading = true;
      const profileProperties = this.generateProfilePropertyModel(model);
      const request = await post(
        SkiDataRoutes.userProfile.updateProfile,
        JSON.stringify(profileProperties),
      );
      const response = await request.json();

      if (request.ok) {
        this.globalStore.showDialog(
          DialogType.Success,
          'Success',
          'Your profile has been successfully updated.',
        );
      } else {
        this.globalStore.showDialog(
          DialogType.Error,
          'Update Profile',
          `${response.Message}`,
        );
      }
    } catch (err) {
      this.globalStore.showDialog(DialogType.Error, 'Update Profile', err);
    } finally {
      this.isLoading = false;
    }
  };

  @action registerUser = async (userRegistration: UserRegistrationModel) => {
    try {
      this.isLoading = true;
      const registrationForm = this.generateProfileFormModel(userRegistration);
      const request = await post(
        `${SkiDataRoutes.userRegistration.registration}/46`,
        JSON.stringify(registrationForm),
      );
      const response = await request.json();
      if (response && response.Success) {
        await this.authStore.validate(
          userRegistration.email,
          userRegistration.password,
        );
        this.resetRegistrationForm();
      } else {
        let message =
          response.FailureMessage ||
          response.Message ||
          'An error occurred when trying to register you.';
        if (response && response.RegistrationForm) {
          if (response.RegistrationForm.EmailValidationMessage) {
            message =
              'An account with this e-mail has already been created or the e-mail is invalid.';
          }
        }
        this.globalStore.showDialog(DialogType.Error, 'Sign Up', message);
      }
    } catch (err) {
      this.globalStore.showDialog(DialogType.Error, 'Sign Up', err);
    } finally {
      this.isLoading = false;
    }
  };

  @action resetRegistrationForm = () => {
    this.userRegistration = new UserRegistrationModel();
  };

  @action resetStore = () => {
    this.userModel = null;
    this.resetRegistrationForm();
  };

  @action uploadPhoto = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      const target = e.target as HTMLInputElement;
      const files = target.files as FileList;
      if (files.length > 0) {
        this.isLoading = true;

        let formData = new FormData();
        formData.append('file', files[0]);

        const request = await fileUpload(
          `${SkiDataRoutes.profileImage.upload}/${this.userModel?.Data.UserID}`,
          formData,
        );
        const response = await request.json();

        if (request.ok) {
          await this.getUser();
        } else {
          this.globalStore.showDialog(
            DialogType.Error,
            'User Photo',
            response.Message,
          );
        }
      }
    } catch (err) {
      this.globalStore.showDialog(DialogType.Error, 'User Photo', err);
    } finally {
      this.isLoading = false;
    }
  };

  /**
   * Return a user profile property given a definition id.
   * @param definitionId the profile property id.
   */
  getProfilePropertyByDefinitionId = (
    definitionId: number,
  ): any | null | undefined => {
    const profileProperty = this.userModel?.Data.ProfileProperties.find(
      (f) => f.PropertyDefinitionID === definitionId,
    )?.PropertyValue;
    if (definitionId === 1732 && !profileProperty) {
      return null;
    } else if (!profileProperty) {
      return '';
    } else {
      return profileProperty;
    }
  };

  /**
   * Returns the current user's GatewayLoyaltyAccountID which has a PropertyDefinitionID of 2508.
   * */
  getUserLoyaltyAccountId = (): string | null => {
    const profileProperty = this.userModel?.Data.ProfileProperties.find(
      (f) => f.PropertyDefinitionID === 2508,
    );
    if (profileProperty) {
      return profileProperty.PropertyValue || null;
    } else {
      return null;
    }
  };

  /**
   * Returns the current user id as a number or undefined if it is not present.
   * */
  public getUserId = (): number | undefined => this.userModel?.Data.UserID;

  private generateProfileFormModel = (
    userRegistration: UserRegistrationModel,
  ): RegistrationFormModel => {
    const displayName = `${
      userRegistration.firstName
    } ${userRegistration.lastName.charAt(0)}.`;
    const registrationForm = new RegistrationFormModel();
    registrationForm.Username = userRegistration.email;
    registrationForm.Password = userRegistration.password;
    registrationForm.Email = userRegistration.email;
    registrationForm.DisplayName = displayName;
    registrationForm.ProfileProperties = this.generateProfilePropertyArray(
      userRegistration,
    );
    return registrationForm;
  };

  private generateProfilePropertyArray = (
    userRegistration: UserRegistrationModel,
  ): Array<ProfilePropertyFormModel> => {
    return Array<ProfilePropertyFormModel>(
      new ProfilePropertyFormModel('Gender', userRegistration.gender),
      new ProfilePropertyFormModel(
        'offersemail',
        userRegistration.offersemail ? 'true' : 'false',
      ),
      new ProfilePropertyFormModel(
        'Birthdate',
        userRegistration.birthDate &&
          format(userRegistration.birthDate, 'MM/dd/yyyy'),
      ),
      new ProfilePropertyFormModel('PostalCode', userRegistration.postalCode),
      new ProfilePropertyFormModel('FirstName', userRegistration.firstName),
      new ProfilePropertyFormModel('LastName', userRegistration.lastName),
    );
  };

  private generateProfilePropertyModel = (
    model: ProfileUpdateModel,
  ): Array<ProfilePropertyModel> => {
    const firstName = this.getProfilePropertyByDefinitionId(1734);
    const lastName = this.getProfilePropertyByDefinitionId(1736);
    const postalCode = this.getProfilePropertyByDefinitionId(1743);
    const gender = this.getProfilePropertyByDefinitionId(1731);
    const birthDate = this.getProfilePropertyByDefinitionId(1732);
    let profileProperties = Array<ProfilePropertyModel>();

    if (model.firstName && model.firstName !== firstName) {
      const firstNameProperty = new ProfilePropertyModel();
      firstNameProperty.UserID = this.userModel?.Data.UserID;
      firstNameProperty.PropertyDefinitionID = 1734;
      firstNameProperty.PropertyValue = model.firstName;
      profileProperties.push(firstNameProperty);
    }

    if (model.lastName && model.lastName !== lastName) {
      const lastNameProperty = new ProfilePropertyModel();
      lastNameProperty.UserID = this.userModel?.Data.UserID;
      lastNameProperty.PropertyDefinitionID = 1736;
      lastNameProperty.PropertyValue = model.lastName;
      profileProperties.push(lastNameProperty);
    }

    if (model.postalCode && model.postalCode !== postalCode) {
      const postalCodeProperty = new ProfilePropertyModel();
      postalCodeProperty.UserID = this.userModel?.Data.UserID;
      postalCodeProperty.PropertyDefinitionID = 1743;
      postalCodeProperty.PropertyValue = model.postalCode;
      profileProperties.push(postalCodeProperty);
    }

    if (model.gender && model.gender !== gender) {
      const genderProperty = new ProfilePropertyModel();
      genderProperty.UserID = this.userModel?.Data.UserID;
      genderProperty.PropertyDefinitionID = 1731;
      genderProperty.PropertyValue = model.gender;
      profileProperties.push(genderProperty);
    }

    if (model.birthDate && typeof model.birthDate === 'object') {
      let newDate = format(model.birthDate, 'MM/dd/yyyy');
      if (birthDate !== newDate) {
        const birthDateProperty = new ProfilePropertyModel();
        birthDateProperty.UserID = this.userModel?.Data.UserID;
        birthDateProperty.PropertyDefinitionID = 1732;
        birthDateProperty.PropertyValue = newDate;
        profileProperties.push(birthDateProperty);
      }
    }

    return profileProperties;
  };
}

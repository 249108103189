import BaseStore from '../BaseStore';
import UserStore from '../UserStore';
import { post } from '../../../common/network/agent';
import { DialogType } from '../../../common/enums/DialogType';
import GlobalStore from '../GlobalStore';
import SkiDataRoutes from '../../../common/config/routes/skidata-routes';
import LoyaltyCodeResponseModel from '../../../common/models/loyalty/code/LoyaltyCodeResponseModel';

/**
 * Handles loyalty code associated requests.
 * */
export default class LoyaltyCodeStore extends BaseStore {
  constructor(private userStore: UserStore, private globalStore: GlobalStore) {
    super();
  }

  /**
   * Mediates the sending of loyalty code to SkiData, and handles the response
   * outcome.
   * @param loyaltyCode the loyalty code that will be applied.
   * */
  applyLoyaltyCode = async (loyaltyCode: string) => {
    try {
      this.isLoading = true;
      const request = await post(
        `${SkiDataRoutes.loyaltyCodes}/${this.userStore.userModel?.Data.UserID}`,
        JSON.stringify({
          portalId: this.userStore.userModel?.Data.PortalID,
          userId: this.userStore.userModel?.Data.UserID,
          loyaltyCode: loyaltyCode,
          processNow: true,
        }),
      );

      const response = (await request.json()) as LoyaltyCodeResponseModel;
      if (response.Success) {
        await this.userStore.getUser();
        this.globalStore.showDialog(
          DialogType.Success,
          'Loyalty Code',
          response.PointsEarned > 0
            ? `Congratulations! You were awarded with ${response.PointsEarned} points`
            : "You've been entered into the contest!",
        );
      } else {
        this.globalStore.showDialog(
          DialogType.Error,
          'Loyalty Code',
          `${response.FailureMessage}`,
        );
      }
    } catch (err) {
      this.globalStore.showDialog(DialogType.Error, 'Loyalty Code', err);
    } finally {
      this.isLoading = false;
    }
  };
}

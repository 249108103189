import React, { Fragment, ReactNode, useState } from 'react';
import { AppBar } from '@material-ui/core';
import TopAppbarStyles from './TopAppbarStyles';
import AppDrawer from '../app-drawer';
import AppToolBar from '../app-toolbar/AppToolBar';

interface TopAppbarProps {
  children?: ReactNode;
  title?: string;
  showUserIcon?: boolean;
  backButtonActionOrUrl?: Function | string;
  position?: 'fixed' | 'absolute' | 'sticky' | 'static' | 'relative';
  transparent?: boolean;
  height?: number;
  actionButton?: ReactNode;
  showPromoCodeIcon?: boolean;
  isDetailAppBar?: boolean;
}

const TopAppbar: React.FC<TopAppbarProps> = (props) => {
  const classes = TopAppbarStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);
  return (
    <Fragment>
      <AppBar
        position={props.position ? props.position : 'fixed'}
        elevation={8}
        style={{
          height: props.height ? props.height : '',
        }}
        className={
          props.transparent ? classes.transparentAppBar : classes.appBar
        }
      >
        <AppToolBar
          backButtonActionOrUrl={props.backButtonActionOrUrl}
          setDrawerOpen={setDrawerOpen}
          title={props.title}
          showPromoCodeIcon={props.showPromoCodeIcon}
          showUserIcon={props.showUserIcon}
          actionButton={props.actionButton}
        />
        {props.children}
      </AppBar>
      <AppDrawer onClose={() => setDrawerOpen(false)} open={drawerOpen} />
    </Fragment>
  );
};

export default TopAppbar;

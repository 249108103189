import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import { EXTERNAL_LINK } from '../../../common/constants/promotion-constants';
import { ga } from 'react-ga';
import { history } from '../../../index';
import Stores from '../../../domain/stores/Stores';
import { observer } from 'mobx-react-lite';
import PromotionStyles from './PromotionStyles';

interface PageBannerProps {
  showThumbs?: boolean;
  showIndicators?: boolean;
  showArrows?: boolean;
  autoPlay?: boolean;
  infiniteLoop?: boolean;
  interval?: number;
  swipeable?: boolean;
  showStatus?: boolean;
}

const PromotionBanner: React.FC<PageBannerProps> = (props) => {
  const classes = PromotionStyles();
  const { promotionStore } = React.useContext(Stores);
  return (
    <Carousel
      showThumbs={props.showThumbs || false}
      showIndicators={props.showIndicators || true}
      showArrows={props.showArrows || true}
      autoPlay={props.autoPlay || true}
      infiniteLoop={props.infiniteLoop || true}
      interval={props.interval || 5000}
      swipeable={props.swipeable || true}
      showStatus={props.showStatus || false}
    >
      {promotionStore.data?.banners?.map((banner) => {
        return (
          <div
            key={banner.id}
            className={classes.bannerContainer}
            onClick={() => {
              if (banner.link.type === EXTERNAL_LINK) {
                ga(
                  'send',
                  'event',
                  'outbound',
                  'click',
                  `Banner - ${banner.link.url}`,
                  {
                    transport: 'beacon',
                    hitCallback: function () {
                      console.log(banner.link.url);
                    },
                  },
                );
                // I am using _blank explicilty here because, I have seen instances
                // of banner.link.target to be null. So to be on the safe side.
                window.open(banner.link.url, '_blank');
              } else {
                history.push(`${banner.link.url}/${banner.id}`);
              }
            }}
          >
            <img
              src={banner.featureImage}
              alt={banner.link.name}
              className={classes.bannerImage}
              loading={'lazy'}
            />
          </div>
        );
      })}
    </Carousel>
  );
};

export default observer(PromotionBanner);

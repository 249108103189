import ProfilePropertyFormModel from './ProfilePropertyFormModel';

export default class RegistrationFormModel {
  public Username?: string;
  public Password?: string;
  public Email?: string;
  public DisplayName?: string;
  public CreateSeatEntities?: boolean | null;
  public SendAdminRegistrationEmail?: boolean | null;
  public SendAdminVerificationEmail?: boolean | null;
  public RegistrationChannel?: string | null;
  public ReferralChannel?: string | null;
  public InvitationID?: number | null;
  public ReferralCode?: number | null;
  public ProfileProperties?: ProfilePropertyFormModel[] | null;
}

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const AppToolBarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      width: 'inherit',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    actionBarContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    pointsContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    menuButton: {
      marginRight: theme.spacing(2),
      mixBlendMode: 'inherit',
    },
  }),
);

export default AppToolBarStyles;

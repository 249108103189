import React, { useContext } from 'react';
import {
  Divider,
  List,
  ListItem,
  ListItemText,
  Drawer,
  ListItemIcon,
  Typography,
  Avatar,
  ListItemAvatar,
  IconButton,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import Stores from '../../../domain/stores/Stores';
import { history } from '../../../index';
import ContactSupportOutlinedIcon from '@material-ui/icons/ContactSupportOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import AppDrawerStyles from './AppDrawerStyles';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import MyWem from '../../../common/resources/images/mywem.svg';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import { ga } from 'react-ga';
import {
  WEMFacebook,
  WEMInstagram,
  WEMTwitter,
} from '../../../common/config/routes/SocialMedia';

interface AppDrawerProps {
  onClose: () => void;
  open: boolean;
}

const AppDrawer: React.FC<AppDrawerProps> = (props) => {
  const { authStore, userStore, globalStore } = useContext(Stores);
  const classes = AppDrawerStyles();
  return (
    <Drawer onClose={props.onClose} open={props.open} anchor={'left'}>
      <List className={classes.root}>
        <div>
          <ListItem className={classes.avatarListItem}>
            <ListItemAvatar>
              <Avatar
                alt={'MyWem Logo'}
                src={MyWem}
                className={classes.avatar}
              />
            </ListItemAvatar>
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <Typography align={'center'} gutterBottom={true}>
                  Hello, {userStore.userModel?.Data.DisplayName}
                </Typography>
              }
              secondary={
                <Typography align={'center'} variant={'subtitle2'}>
                  {userStore.userModel?.Data.CurrentPoints?.PointsRemaining.toLocaleString(
                    'en-US',
                    { maximumSignificantDigits: 3 },
                  )}{' '}
                  Points
                </Typography>
              }
            />
          </ListItem>
          <Divider />
          <ListItem key={'media-icons'} className={classes.socialMediaListItem}>
            <Typography variant={'caption'} align={'left'}>
              Check us out on social media
            </Typography>
            <div className={classes.socialMediaIconContainer}>
              <IconButton
                onClick={() => {
                  ga(
                    'send',
                    'event',
                    'outbound',
                    'click',
                    `WEM Facebook - ${WEMFacebook}`,
                    {
                      transport: 'beacon',
                      hitCallback: function () {
                        console.log(WEMFacebook);
                      },
                    },
                  );
                  window.open(WEMFacebook, '_blank');
                }}
              >
                <FacebookIcon className={classes.facebookIcon} />
              </IconButton>
              <IconButton
                onClick={() => {
                  ga(
                    'send',
                    'event',
                    'outbound',
                    'click',
                    `WEM Instagram - ${WEMInstagram}`,
                    {
                      transport: 'beacon',
                      hitCallback: function () {
                        console.log(WEMInstagram);
                      },
                    },
                  );
                  window.open(WEMInstagram, '_blank');
                }}
              >
                <InstagramIcon className={classes.instagramIcon} />
              </IconButton>
              <IconButton
                onClick={() => {
                  ga(
                    'send',
                    'event',
                    'outbound',
                    'click',
                    `WEM Twitter - ${WEMTwitter}`,
                    {
                      transport: 'beacon',
                      hitCallback: function () {
                        console.log(WEMTwitter);
                      },
                    },
                  );
                  window.open(WEMTwitter, '_blank');
                }}
              >
                <TwitterIcon className={classes.twitterIcon} />
              </IconButton>
            </div>
          </ListItem>
          <Divider />
          <ListItem
            button
            key={'support'}
            onClick={() => history.push('/support')}
            selected={globalStore.routeValue === 5}
          >
            <ListItemIcon>
              <ContactSupportOutlinedIcon color={'primary'} />
            </ListItemIcon>
            <ListItemText primary={<Typography>CONTACT SUPPORT</Typography>} />
          </ListItem>
          <Divider />
          <ListItem
            button
            key={'faqs'}
            onClick={() => history.push('/faqs')}
            selected={globalStore.routeValue === 7}
          >
            <ListItemIcon>
              <HelpOutlineOutlinedIcon color={'primary'} />
            </ListItemIcon>
            <ListItemText primary={<Typography>FAQs</Typography>} />
          </ListItem>
          <Divider />
          <ListItem
            button
            key={'privacy'}
            onClick={() => history.push('/privacy')}
            selected={globalStore.routeValue === 8}
          >
            <ListItemIcon>
              <VisibilityOutlinedIcon color={'primary'} />
            </ListItemIcon>
            <ListItemText primary={<Typography>PRIVACY</Typography>} />
          </ListItem>
          <Divider />
          <ListItem
            button
            key={'terms'}
            onClick={() => history.push('/terms')}
            selected={globalStore.routeValue === 9}
          >
            <ListItemIcon>
              <AssignmentTurnedInOutlinedIcon color={'primary'} />
            </ListItemIcon>
            <ListItemText primary={<Typography>TERMS</Typography>} />
          </ListItem>
          <Divider />
        </div>
        <div>
          <ListItem
            button
            key={'logout'}
            onClick={async () => await authStore.logout()}
          >
            <ListItemIcon>
              <ExitToAppOutlinedIcon color={'primary'} />
            </ListItemIcon>
            <ListItemText primary={<Typography>LOGOUT</Typography>} />
          </ListItem>
        </div>
      </List>
    </Drawer>
  );
};

export default observer(AppDrawer);

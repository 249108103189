import React, { Dispatch, SetStateAction } from 'react';
import Barcode from 'react-barcode';
import { Button, Paper, Typography } from '@material-ui/core';
import LoyaltyCardStyles from './LoyaltyCardStyles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

interface LoyaltyCardProps {
  value: string | null;
  showLoyaltyUsageDialog: Dispatch<SetStateAction<boolean>>;
}

const LoyaltyCard: React.FC<LoyaltyCardProps> = ({
  value,
  showLoyaltyUsageDialog,
}) => {
  const classes = LoyaltyCardStyles();
  return value ? (
    <Paper elevation={5} className={classes.root}>
      <div className={classes.info}>
        <Typography variant={'h5'}>
          <span className={classes.titleColor}>My</span>WEM
        </Typography>
        <Button
          variant={'text'}
          startIcon={<InfoOutlinedIcon />}
          color={'secondary'}
          onClick={() => showLoyaltyUsageDialog(true)}
        >
          <Typography variant={'subtitle2'}>Loyalty Card</Typography>
        </Button>
      </div>
      <div className={classes.barcodeContainer}>
        <Barcode value={value} />
      </div>
    </Paper>
  ) : null;
};

export default LoyaltyCard;

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const NotFoundStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    icon: {
      width: 350,
      height: 350,
    },
  }),
);

export default NotFoundStyles;

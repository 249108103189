import React, { Dispatch, Fragment, SetStateAction } from 'react';
import {
  Checkbox,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import DealCategoryModel from '../../../common/models/loyalty/deals/DealCategoryModel';

interface DealCategoriesProps {
  categories: DealCategoryModel[];
  currentFilters: DealCategoryModel[];
  updateCategoryFilters: Dispatch<SetStateAction<DealCategoryModel[]>>;
}

const DealCategories: React.FC<DealCategoriesProps> = (props) => {
  return (
    <Fragment>
      {props.categories.map((category) => {
        return (
          <ListItem
            key={category.id}
            role={undefined}
            onClick={() => {
              const clickedCategory = props.currentFilters.find(
                (f) => category.id === f.id,
              );
              if (clickedCategory) {
                props.updateCategoryFilters(
                  props.currentFilters.filter((f) => f.id !== category.id),
                );
              } else {
                props.updateCategoryFilters([
                  ...props.currentFilters,
                  category,
                ]);
              }
            }}
            dense
            button
          >
            <ListItemIcon>
              <Checkbox
                edge="start"
                tabIndex={-1}
                checked={
                  props.currentFilters.find((f) => category.id === f.id) !==
                  undefined
                }
              />
            </ListItemIcon>
            <ListItemText primary={category.text} />
          </ListItem>
        );
      })}
    </Fragment>
  );
};

export default DealCategories;

import React from 'react';
import SentimentVerySatisfiedOutlinedIcon from '@material-ui/icons/SentimentVerySatisfiedOutlined';
import DealStyles from './DealStyles';
import { Grid, Typography } from '@material-ui/core';
import { EmptyDealsMessage } from './DealStaticResources';

const NoContent: React.FC = () => {
  const classes = DealStyles();
  return (
    <div className={classes.emptyDealsContainer}>
      <Grid container spacing={4}>
        <Grid container item xs={12} justify={'center'}>
          <SentimentVerySatisfiedOutlinedIcon
            className={classes.emptyDealsIcon}
            color={'primary'}
          />
        </Grid>
        <Grid container item xs={12} justify={'center'}>
          <Typography component={'h2'} variant={'h6'} align={'center'}>
            {EmptyDealsMessage}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default NoContent;

import BaseStore from '../BaseStore';
import { action, observable } from 'mobx';
import { get } from '../../../common/network/agent';
import SkiDataRoutes from '../../../common/config/routes/skidata-routes';
import _ from 'lodash';
import PointActivityResponseModel from '../../../common/models/loyalty/point/PointActivityResponseModel';
import PointActivityTypeModel from '../../../common/models/loyalty/point/PointActivityTypeModel';
import PointRecordModel from '../../../common/models/loyalty/point/PointRecordModel';
import PointDataResponseModel from '../../../common/models/loyalty/point/PointDataResponseModel';

export default class PointStore extends BaseStore {
  @observable pointActivity: PointActivityResponseModel[] = [];
  @observable pointRecord: PointRecordModel[] = [];
  @observable pointActivityTypes: PointActivityTypeModel[] = [];
  @observable pointTypeFilter: number = -1;

  @action getPointRecord = async () => {
    try {
      this.isLoading = true;
      const request = await get(SkiDataRoutes.points);

      if (request.ok) {
        this.pointRecord = [];
        const response = (await request.json()) as PointDataResponseModel;
        this.pointRecord = response.Data.Records as PointRecordModel[];
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  };

  getPointActivitiesByType = (): PointActivityResponseModel[] => {
    return _(this.pointActivity)
      .filter(
        (f) =>
          f.PointActivityTypeId === this.pointTypeFilter ||
          this.pointTypeFilter === -1,
      )
      .sortBy((s) => s.Ordinal)
      .value();
  };

  @action getPointActivityTypes = async () => {
    const response = await get(SkiDataRoutes.pointActivityType);

    if (response.ok) {
      // Point activity type id to exclude from result.
      const excludedPointActivityTypeId = [358, 359, 361, 362, 364, 489];
      const data = (await response.json()) as PointActivityTypeModel[];
      this.pointActivityTypes = data.filter(
        (f) => !excludedPointActivityTypeId.includes(f.PointActivityTypeId),
      );
    }
  };

  @action getAllPointActivities = async () => {
    try {
      this.isLoading = true;

      // Get point activity types
      await this.getPointActivityTypes();

      const response = await get(SkiDataRoutes.pointActivities);

      if (response.ok) {
        this.pointActivity = (await response.json()) as PointActivityResponseModel[];
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  };
}

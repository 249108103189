import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const LandingStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      '@media all and (display-mode: standalone)': {
        height: '90vh',
      },
      '@media all and (display-mode: browser)': {
        height: '85vh',
      },
    },
    image: {
      height: '40vmin',
    },
    buttonSignInSignOut: {
      backgroundColor: '#54b484',
      color: '#FFFFFF',
      '&:hover': {
        backgroundColor: '#54b484',
      },
    },
    link: {
      color: '#7f7f7f',
      textDecoration: 'none',
    },
    titleColor: {
      color: '#d1a567',
    },
    subtitleColor: {
      color: '#69686d',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  }),
);

export default LandingStyles;

import React, { ReactNode } from 'react';
import { Menu } from '@material-ui/core';

interface DealsMenuProps {
  open: boolean;
  anchorEl: null | HTMLElement;
  keepMounted: boolean;
  onClose: () => void;
  id: string;
  children: ReactNode;
}

const DealsMenu: React.FC<DealsMenuProps> = (props) => {
  return (
    <Menu
      id={props.id}
      open={props.open}
      anchorEl={props.anchorEl}
      keepMounted={props.keepMounted}
      onClose={props.onClose}
    >
      {props.children}
    </Menu>
  );
};

export default DealsMenu;

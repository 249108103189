import React, { Fragment, useContext } from 'react';
import BottomAppBar from '../bottom-appbar';
import { observer } from 'mobx-react-lite';
import Stores from '../../../domain/stores/Stores';
import ProfileStyles from './ProfileStyles';
import { Container } from '@material-ui/core';
import { Formik } from 'formik';
import ProfileSchema from './ProfileSchema';
import ProfileForm from './ProfileForm';
import ProfileUpdateModel from '../../../common/models/user/profile/ProfileUpdateModel';
import TopAppbar from '../top-appbar';

const Profile = () => {
  const { userStore, authStore } = useContext(Stores);
  const classes = ProfileStyles();

  return (
    <Fragment>
      <TopAppbar showUserIcon={false} showPromoCodeIcon={true} />
      <Formik
        initialValues={
          new ProfileUpdateModel(
            userStore.getProfilePropertyByDefinitionId(1734),
            userStore.getProfilePropertyByDefinitionId(1736),
            userStore.getProfilePropertyByDefinitionId(1743),
            userStore.getProfilePropertyByDefinitionId(1731),
            userStore.userModel?.Data.EmailAddress,
            userStore.getProfilePropertyByDefinitionId(1732),
          )
        }
        validationSchema={ProfileSchema}
        onSubmit={async (values) => {
          await userStore.updateUserProfileData(values);
        }}
      >
        {(formikProps) => (
          <Container maxWidth={'md'} className={classes.root}>
            <ProfileForm
              formikProps={formikProps}
              classes={classes}
              userPhoto={userStore.getProfilePropertyByDefinitionId(1752)}
              uploadPhoto={userStore.uploadPhoto}
              resetPassword={authStore.resetPassword}
            />
          </Container>
        )}
      </Formik>
      <BottomAppBar />
    </Fragment>
  );
};

export default observer(Profile);
